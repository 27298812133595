import * as React from "react"

function LinkedIn(props) {
  return (
    <svg viewBox="0 0 432 484" {...props}>
      <path d="M411.463 284.021V427.76h-83.328V293.646c0-33.692-12.057-56.683-42.219-56.683-23.026 0-36.729 15.496-42.759 30.482-2.195 5.361-2.76 12.817-2.76 20.321V427.76h-83.359s1.123-227.146 0-250.681h83.354v35.532c-.168.268-.389.553-.549.812h.549v-.812c11.068-17.055 30.849-41.42 75.112-41.42 54.85 0 95.959 35.83 95.959 112.83zM69.837 56.24c-28.521 0-47.174 18.706-47.174 43.304 0 24.062 18.114 43.334 46.068 43.334h.557c29.065 0 47.148-19.272 47.148-43.334-.548-24.598-18.083-43.304-46.599-43.304zM27.613 427.76h83.328V177.079H27.613V427.76z" />
    </svg>
  )
}

export default LinkedIn
