import React from "react"
import ProjectCard from "./components/ProjectCard";
import LeonardoScreenshot from './img/Leonardo_Screenshot.png';
import StyleGuideMacbook from './img/EllucianStyleGuide_MacBook.png';
import PatternLibrary from './img/ExedePatternLibrary.png';
// import SpectrumScreenshot from './img/Spectrum_Screenshot.png';
import SpectrumScreenshot from './img/Spectrum_Hero.png';
import ProportioScreenshot from './img/ProportioScreenshot.png';
import { BrowserRouter, Route, Link, useParams, useRouteMatch, Switch } from 'react-router-dom'; 
import { AnimatePresence, motion } from "framer-motion";
import Footer from './components/Footer';

import ColorAndConrast from './img/ColorAndContrastHome.png';
import IntuitDesignSystemHero from './img/IDS_Hero.png';

import {
  pageVariants,
  pageTransition,
  pageStyle
} from './components/Animation';

function Projects() {
  let { path, url } = useRouteMatch();

  const mq = window.matchMedia('(prefers-color-scheme: dark)');


  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >

      <h1><span className="highlight">Projects</span></h1>

      {/* <span className="projectHeader">
        <h3 className="h6">Design systems</h3>
      </span> */}

      <div className="projectCardWrapper">
        <ProjectCard 
          title="Intuit Design System"
          image={IntuitDesignSystemHero}
          alt="Intuit design system assets"
          description="Multi-brand, themeable design system for Intuit products and product design systems. Supporting Quickbooks, TurboTax, Mailchimp, Mint, and Credit Karma."
          link="/intuitdesignsystem"
          badgeType="designSystem"
        ></ProjectCard>

        <ProjectCard 
          title="Spectrum Design System"
          image={SpectrumScreenshot}
          alt="Spectrum design system documentation website"
          description="Multi-platform enterprise design system for Adobe products. Supported resources for design, web, iOS, Android, UWP, and custom frameworks for 100+ products worldwide."
          link="/spectrum"
          badgeType="designSystem"
        ></ProjectCard>

        <ProjectCard 
          title="Ellucian Design System"
          image={StyleGuideMacbook}
          alt="Screenshot of Ellucian style guide"
          description="Multi-platform design system for accessible and themable higher education products"
          link="/ellucian"
          badgeType="designSystem"
        ></ProjectCard>

        <ProjectCard 
          title="Exede Design System"
          image={PatternLibrary}
          alt="Screenshot of Exede design system assets"
          description="Design system for internal and customer-facing websites and applications"
          link="/exede"
          badgeType="designSystem"
        ></ProjectCard>
      </div>    
{/* 
      <span className="projectHeader">
        <h3 className="h6">Tools &amp; resources</h3>
      </span> */}

      <div className="projectCardWrapper">
        <ProjectCard 
          title="Color & Contrast.com"
          image={ColorAndConrast}
          alt="Screenshot of Color and Contrast web site"
          description="An interactive online library for color and contrast perception. Subjects are organized, cited, and have context regarding how they affect user interface design."
          link="/colorandcontrast"
          badgeType="website"
        ></ProjectCard>

        <ProjectCard 
          title="Leonardo"
          image={LeonardoScreenshot}
          alt="Screenshot of Leonardo web application"
          description="Web app for creating accessible color palettes for design systems. Built with a sibling javascript module for generating colors in web applications."
          link="/leonardo"
          badgeType="designTool"
        ></ProjectCard>

      <ProjectCard 
          title="Proportio"
          image={ProportioScreenshot}
          alt="Screenshot of Leonardo web application"
          description="Web app for creating proportional design systems. Sizing values are based on typographic scales to ensure balanced sizes and densities for components."
          link="/proportio"
          badgeType="designTool"
        ></ProjectCard>

      </div>
      

      <Footer />
    </motion.div>
  )
}

export default Projects;