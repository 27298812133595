import React from "react"

import Image from "../components/Image";
import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { AnimatePresence, motion } from "framer-motion";
import Footer from '../components/Footer';
import Caption from '../components/Caption';

import MobileUIKit from '../img/Exede_Mobile-UI-Kit.jpg';
import ExedeHero from '../img/Exede_Hero.png';
import PatternLibrary from '../img/ExedePatternLibrary.png';

function Exede(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <h1 style={{alignSelf: 'center'}} ><span className="highlight">Exede design system</span></h1>
      <Image
          src={ExedeHero}
          alt="Screenshot of Leonardo web application"            
        ></Image>
      <Section title="Overview">
        <p>This project was a full-scale rebranding effort for our web-based applications and interfaces. We took existing website designs (creaked by Sukle Advertising) and expanded upon them in order to create a systematic visual language. The goal was to facilitate rapid design, rapid development, consistency across interfaces, increased usability, and a solid consistent user experience across multiple applications.</p>
        
        <h4>My role</h4>
        <p>User interface design, art direction, project management and collaborative front-end development with the web engineering team.</p>
        {/* <Image 
          src={PatternLibrary}
          alt="Screenshot of design for Exede documentation"
        ></Image> */}
      </Section>

      <Section title="Design assets">
        <p>The UI Kits and Templates were built while development of the pattern library began. Design decisions were solidified and adjusted in-browser, in order to ensure our designs were rendering as we desired them to be.  The UI Kits and Templates were built in Sketch and Adobe Photoshop in order to accommodate the needs of vendors and freelance designers.  This made it possible for external designers and creative teams the ability to keep our user experience consistent.</p>
        <Image 
          src={MobileUIKit}
          className="constrained"
          alt="Artboards displaying components for mobile Exede design system"
        ></Image>
        <Caption>Sketch file with mobile web components</Caption>
      </Section>

      <Section title="Front-end development assets">
        <p>Following atomic design principles, each pattern was built from the ground-up with usability, accessibility, and best practices top-of-mind. The pattern library itself is a repository of design elements and code for developers to access, including a bootstrap theme based upon the designs.  Bootstrap was selected as the base CSS framework to facilitate rapid development and ease of use by external vendors developing for our applications.</p>
        <p>The goal was to create a Bootstrap child-theme that could be easily adopted by all development teams. Based on the technology landscape for all their applications, Bootstrap and jQuery were the best solution for easy adoption.</p>
      </Section>

      <Section title="Conclusion">
        <p>Unifying all of our applications was a powerful direction for our products and user experience. Disparate applications built by siloed development teams were now able to follow a guiding light. New products and features could be rapidly prototyped in HTML, and production development was sped up considerably.  The framework itself laid the path for future evolution of the user experience for the entire product suite.</p>
      </Section>

      <Footer />

    </motion.div>
  )
}
 
export default Exede;