import * as React from "react"

function Logo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 309.6 298.4"
      {...props}
    >
      <path d="M94.5 225.8c.2-20.1 1.6-40.2 3.9-60.1 3-26.1 7.1-52.1 12.5-77.8 1.6-7.5 3.3-14.9 5.3-22.3.6-2.1 6.3-16 5.3-17.4-4.4-6.1-16.6-13-24.4-11.6-11.6 13.6-21.9 37.7-30.5 64.6C73.5 60.7 79.1 25 79.3 11.4 74.9 5.6 62.4-1.6 54.6.3 28.5 25.8 0 77.6 0 115.3c0 30.2 13.9 45.2 30.8 36.6C25 141.1 18.9 122.5 18.9 99c0-25.8 12.5-61 34.6-80.1-5.3 83.7-17.5 164.9-32.4 219.8 3 4.7 11.6 7.2 17.5 5 4.4-19.1 11.6-54.9 18.8-92.9 9.1-34.4 21.9-71.5 36-90.9-4.6 30.2-8.4 60.5-11.5 90.8-1.9 18.3-2.6 36.7-3.8 55.1-1.6 25.3-3.2 50.6-4 75.9-.1 4.3-.4 8.7-.2 13 .1 2.6 0 3.8 3 3.7 5.5-.1 11.5-2 16.3-4.7 5.4-2.9 3.2-9.6 3-14.7-.6-17.7-1.7-35.4-1.7-53.2zM308.1 51.6c-32.4 8.3-61.8 9.7-92 7.2 2.5-10.3 4.4-18.3 5.5-24.1-5.5-6.4-17.2-11.6-24.9-10.8-1.7 8-3.3 19.4-5 32.4-12.2-1.4-33.6-3.5-56.8-8.3-2.8 3.9-2.5 9.7 0 12.5 19.2 4.9 42.1 10 54.6 11.7-3.1 25.3-6 54.7-8 80.3-3.4 15.3-8.3 30.5-15 40.8-1.1-23-.8-56.8 11.4-91.5-2.2-3.6-8.9-7.2-15.2-8.9l.6-3.9c-3.6-6.7-14.1-17.7-21.1-17.5-14.4 7.5-30.8 39.6-30.8 86.8 0 14.7 1.7 31 5.5 48.5 3 3 9.4 6.9 14.7 7.2 7.5-7.8 13.3-23 17.7-44.1 0 12.7.6 24.7 1.9 34.4 2.5 2.8 8.3 4.7 12.5 4.7 5-5.8 10.9-18.3 16.2-32.5-.5 10.1-.8 18.8-.7 25.6 2.2 3.3 10.3 7.2 15.8 6.9 4.8-5.5 10.5-16.5 15.9-29.2 1.2 8 3.3 16.2 6.3 24.5 3 2.8 9.7 5.3 16.1 5.3 23.6-12.3 48.8-56.8 48.8-85.9 0-10.5-5-12.5-8.9-11.4-.3 37.7-16.9 70.7-40.2 87-2.8-5.3-5-14.1-5.3-26.6 3 2.5 6.7 4.2 10 5 18-16.6 25.2-56.5 26.1-70.1-6.4-10.8-18-17.5-26.6-17.5-13.6 7.2-27.7 35.2-27.7 70.1 0 2.6.1 5.2.2 7.9-3.7 11.2-8.4 21.3-14.1 28.5 2.2-51 10-92.3 16.6-121.7 33.8 3 68.2 3 90.1.8 6.3-5.8 9.4-20.8 5.8-24.1zm-158 86.2c-5 32.4-13 57.1-19.1 63.7-.6-4.7-4-19.5-4-35.8-.3-35.5 8-54 20.9-65.1 1.9 6.1 0 23.3-2.8 31.3 1.4 1.1 3.3 1.7 5.3 1.4l-.3 4.5zm95.3-26.4c1.4 6.9-1.7 38.8-11.1 56.5-2.5-1.4-4.4-4.2-6.4-7.2 1.5-19.9 7-36.8 17.5-49.3z" />
    </svg>
  )
}

export default Logo
