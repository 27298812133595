import * as React from "react"

function EllucianLogoMark(props) {
  return (

<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M40.4831 4.0538C26.1397 -1.75111 9.81382 5.16787 4.04147 19.5335C-1.73088 33.8993 5.14931 50.1999 19.4926 55.9462C33.8361 61.7511 50.1619 54.8321 55.9343 40.4664C61.7649 26.1593 54.8264 9.85871 40.4831 4.0538ZM49.4622 37.8865C45.0893 48.6167 32.9032 53.8353 22.1165 49.4963C11.3881 45.1572 6.19882 32.9024 10.5135 22.1722C14.42 12.5559 24.5654 7.45465 34.3608 9.5069C35.5271 9.74143 36.6932 10.0933 37.8593 10.5623C39.0254 11.0314 40.0749 11.6178 41.0661 12.2041L38.3257 18.9473L31.9121 34.8375L25.2068 32.1402L31.6204 16.25C25.5565 15.4877 19.551 18.8885 17.1604 24.8107C14.3034 31.9057 17.7435 39.9973 24.7986 42.8119C31.9121 45.6849 39.9583 42.2842 42.8153 35.1892C44.1564 31.7883 44.0981 28.2116 42.8736 25.0452L46.2554 16.7777C50.9199 22.4653 52.4359 30.4985 49.4622 37.8865Z" fill="var(--ellucianLogoMark)"/>
</svg>


  )
}

export default EllucianLogoMark