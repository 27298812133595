import React from "react"
import './Experience.scss';

const ExperienceSmall = (props) => {
    const title = props.title;
    const institution = props.institution;
    const year = props.year;

    return (

        <article className="Experience">
            <div className="Experience-Header">
                <h5><span>{title}</span> <span>{year}</span></h5>
                <div className="Experience-SubHeader">
                    <span className="Experience-Company">{institution}</span>
                </div>
            </div>
            <div className="Experience-Details">
                {props.children}
            </div>
        </article>

    )
  
}
 
export default ExperienceSmall;