import * as React from "react"

const AdobeLogoMark = (props) => {
  return (
    <svg width="60" height="52" viewBox="0 0 60 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1_2)">
    <path d="M22.4175 0.288948H0.823685V51.9313L22.4175 0.288948Z" fill="#FA0C00"/>
    <path d="M37.6106 0.288948H59.1763V51.9313L37.6106 0.288948Z" fill="#FA0C00"/>
    <path d="M30.0141 19.3225L43.7582 51.9313H34.7408L30.6331 41.5494H20.5747L30.0141 19.3225Z" fill="#FA0C00"/>
    </g>
    <defs>
    <clipPath id="clip0_1_2">
    <rect width="60" height="52" fill="transparent"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default AdobeLogoMark