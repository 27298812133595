import * as React from "react"

function Twitter(props) {
  return (
    <svg viewBox="0 0 182.7 150.7" {...props}>
      <path d="M182.2 18.8c-6.7 3-13.9 5-21.5 5.9 7.7-4.6 13.6-12 16.4-20.7-7.1 4.2-15.1 7.4-23.6 9-6.8-7.3-16.5-11.8-27.3-11.8-20.7 0-37.4 16.7-37.4 37.4 0 2.9.3 5.8 1 8.5-31.1-1.5-58.7-16.4-77.1-39C9.5 13.6 7.6 20 7.6 26.9c0 13 6.6 24.4 16.6 31.1-6.1-.2-11.9-1.9-16.9-4.7v.5c0 18.1 12.9 33.2 30 36.7-3.1.9-6.4 1.3-9.8 1.3-2.4 0-4.8-.2-7-.7 4.8 14.9 18.6 25.7 34.9 26-12.8 10-28.9 16-46.4 16-3 0-6-.2-8.9-.5 16.6 10.6 36.2 16.8 57.3 16.8 68.8 0 106.4-57 106.4-106.4 0-1.6 0-3.2-.1-4.8 7.2-5.4 13.5-11.9 18.5-19.4" />
    </svg>
  )
}

export default Twitter
