import React from "react"

import Image from "../components/Image";
import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { AnimatePresence, motion } from "framer-motion";
import Footer from '../components/Footer';

import CommandingComprehension from '../img/Commanding-Comprehension-Nate-Baldwin.jpg';
import CuringTheDisease from '../img/Curing-the-Disease-Nate-Baldwin.jpg';
import Diner from '../img/Diner-Nate-Baldwin.jpg';
import StandardProcedure from '../img/Standard-Procedure-Nate-Baldwin.jpg';
import LipsStudy from '../img/Lips-Study-Nate-Baldwin.jpg';
import Repose from '../img/Repose-Nate-Baldwin.jpg';
import TwoMoons from '../img/Two-Moons-Nate-Baldwin.jpg';
import SelfPortrait1 from '../img/Self-Portrait-Nate-Baldwin.jpg';
import SelfPortrait2 from '../img/Self-Portrait-2-Nate-Baldwin.jpg';


function FineArt(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <h1><span className="highlight">Fine art</span></h1>
      <Section title="Hyperrealistic paintings">
        <p>This is a selection of works I have created over the years. I am fascinated by illusions and visual perception, especially in regards to the illusion of reality.</p>
        <p>A number of my works focused on metaphors for the experiences and destigmatization of mental health issues, while other of my work was more observational.</p>
        <p><strong>Discression is advised:</strong> Some of these images depict violent imagery.</p>
      </Section>

      <Section title="Commanding Comprehension">
        <p className="ArtDetails">Acrylic on canvas</p>
        <p className="ArtDetails">60in x 72in</p>
        <Image 
          className="Painting"
          src={CommandingComprehension}
          alt="Patient with nurses and doctor. Patient has long hair and gown, and is holding the doctor's finger into a slit in his wrist as nurses look on in shock."            
        ></Image>

        <p>
          This painting is a contemporary appropriation of the classical narrative "The Incredulity of Saint Thomas." The metaphor represents the lack of empathy and comprehension of medical professionals with patients living with mental illness. Treatments are superficial attempts to solve a presumed ailment; rarely do they investigate to truly understand the problems their patients face, and the proper way to help lift them to a point of stable health.
        </p>
        <p>
          In this painting, the patient thrusts his doctor's finger into his self-inflicted wounds as if to force her to know that the wounds are real, and to feel the metaphorical flesh that has been torn. In this moment, the doctor gasps as she feels the reality and the weight of the burdens and pain her patient lives with every day.
        </p>

      </Section>

      <Section title="Standard Procedure">
        <p className="ArtDetails">Acrylic on canvas</p>
        <p className="ArtDetails">66in x 102in</p>
        <Image
          className="Painting"
          src={StandardProcedure}
          alt="Three figures, one is a patient held down by a nurse with his mouth taped shut as a blindfolded doctor injects him with a needle."
        ></Image>
        <p>Standard Procedure is a metaphorical narrative for how patients and people with a mental illness are treated by professionals. The blindfolded doctor represents authoritative figures who blindly follow procedures and diagnostics by-the-book, disregarding the responsibility to listen carefully to each patient.  The patient is held down and gagged, desperate to be listened to, as the standard treatment could be completely wrong for his illness.  Though this is not a blanketing opinion for all mental health professionals, this painting is aimed towards raising awareness for the practices of those who fit this profile.</p>
      </Section>

      <Section title="Curing the Disease">
        <p className="ArtDetails">Acrylic on canvas</p>
        <p className="ArtDetails">102in x 66in</p>
        <Image
          className="Painting"
          src={CuringTheDisease}
          alt="Three figures of the same person, two attacking the third whom is lying face down with mouth agape over a street curb."
        ></Image>

        <p>This image depicts three figures of the same identity. The main figure in the foreground elicits a sense of urgency and panic by the terror in his eyes from being beaten and bloodied by his attackers; himself. His other likeness, slightly blurred to suggest the fog of mental health crisis, angrily swings a baseball bat to "curb" his other self. The furthest figure is even more distant, spitting on the foreground figure in disgust.</p>
        <p>"Curing the Disease" explores lesser-known emotional aspects of suicide; self-hate, disgust, apathy, and panic. This trinity of self is frozen in a perpetual state of near catastrophy, just as those suffering suicidal thoughts are near moments from potential harm.</p>
        <p>If you or someone you know is suffering from suicidal thoughts, please seek help immediately.</p>
      </Section>

      <Section title="Lips (Study)">
        <p className="ArtDetails">Acrylic on canvas</p>
        <p className="ArtDetails">18in x 18in</p>
        <Image
          className="Painting"
          src={LipsStudy}
          alt="Close up image of a woman's lips"
        ></Image>

        {/* <p></p> */}
      </Section>

      <Section title="Repose">
        <p className="ArtDetails">Oil on canvas</p>
        <p className="ArtDetails">36in x 48in</p>
        <Image
          className="Painting"
          src={Repose}
          alt="Figure laying in rest atop a sandstone spire surrounded by red sandstone cliffs"
        ></Image>

        {/* <p>Description</p> */}
      </Section>

      <Section title="Diner">
        <p className="ArtDetails">Oil on canvas</p>
        <p className="ArtDetails">12in x 16in</p>
        <Image
          className="Painting"
          src={Diner}
          alt="Front entryway of a diner"
        ></Image>

        <p>A small homage to my photorealism predecessors such as Ralph Goings, whom frequently painted the subject of Diners.</p>
      </Section>

      <Section title="Two Moons">
        <p className="ArtDetails">Oil on canvas</p>
        <p className="ArtDetails">36in x 18in</p>
        <Image
          className="Painting"
          src={TwoMoons}
          alt="Native American with full headdress looks upward in commanding expression"
        ></Image>

        <p>Painted from an <a href="https://www.loc.gov/pictures/item/2002722460/" target="_blank">Edward S. Curtis photograph</a> of Cheyenne Chief Two Moons.</p>
      </Section>

      <Section title="Self Portrait (1)">
        <p className="ArtDetails">Oil on canvas</p>
        <p className="ArtDetails">36in x 24in</p>
        <Image
          className="Painting"
          src={SelfPortrait1}
          alt="Mans face staring directly at viewer"
        ></Image>

        <p>Inspired by one of my most influential artists, Chuck Close, this is a personal replication of his famous <a href="https://walkerart.org/collections/artworks/big-self-portrait" target="_blank" >Big Self Portrait</a>, but at a much smaller scale.</p>
      </Section>

      <Section title="Self Portrait (2)">
        <p className="ArtDetails">Oil on canvas</p>
        <p className="ArtDetails">36in x 24in</p>

        <Image
          className="Painting"
          src={SelfPortrait2}
          alt="Man with shaved head and closed eyes facing the viewer"
        ></Image>
        <p>This painting is a response to the black-and-white self portait, illustrating the dualities of bipolar disorder by showing the same person in opposing fashion; unclothed, shaved, with eyes closed to hide from the viewer, and in full color with harsh central light source.</p>
      </Section>

      <Footer />

    </motion.div>
  )
}
 
export default FineArt;