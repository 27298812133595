import * as React from "react"

function Brightness(props) {
  return (
<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_15_923)">
<path d="M23.4167 13H24.4583M13 2.58334V1.54167M13 24.4583V23.4167M21.3333 21.3333L20.2917 20.2917M21.3333 4.66667L20.2917 5.70834M4.66667 21.3333L5.70833 20.2917M4.66667 4.66667L5.70833 5.70834M1.54167 13H2.58333M13 19.25C14.6576 19.25 16.2473 18.5915 17.4194 17.4194C18.5915 16.2473 19.25 14.6576 19.25 13C19.25 11.3424 18.5915 9.75269 17.4194 8.58059C16.2473 7.40849 14.6576 6.75 13 6.75C11.3424 6.75 9.75269 7.40849 8.58058 8.58059C7.40848 9.75269 6.75 11.3424 6.75 13C6.75 14.6576 7.40848 16.2473 8.58058 17.4194C9.75269 18.5915 11.3424 19.25 13 19.25Z" stroke="var(--gray800)" strokeWidth="2.39583" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_15_923">
<rect width="26" height="26" fill="none"/>
</clipPath>
</defs>
</svg>


  )
}

export default Brightness