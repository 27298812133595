import * as React from "react"

function Accessibility(props) {
  return (
    <svg
      className="icon-Accessibility"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 18 18"
      xmlSpace="preserve"
      {...props}
    >
      <path
        // className="icon-accessibility"
        d="M9 .9c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM9 3c.7 0 1.3.6 1.3 1.3S9.7 5.7 9 5.7s-1.3-.6-1.3-1.4S8.3 3 9 3zm4.8 3.9l-2.9.7c-.5.1-.8.5-.8 1v.9c0 .2.1.4.2.5l2.3 3.6c.2.4.1.9-.2 1.1-.1.1-.3.1-.4.1-.3 0-.5-.1-.7-.4L9 11l-2.1 3.4c-.2.4-.7.5-1.1.2-.4-.2-.5-.7-.2-1.1L7.8 10c.1-.2.2-.4.2-.5v-.9c0-.5-.3-.9-.8-1l-3-.7c-.4-.1-.7-.5-.6-1 .1-.4.5-.7 1-.6l3.9.9c.3.1.7.1 1 0l3.9-.9c.4-.1.9.2 1 .6.1.5-.2.9-.6 1z"
      />
    </svg>
  )
}

export default Accessibility