import React from "react"
import { Link } from 'react-router-dom'; 

import Image from "../components/Image";
import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import {  motion } from "framer-motion";
import Footer from '../components/Footer';
import Caption from '../components/Caption';


import ColorAndContrastHero from '../img/ColorAndContrastHero.png';
import Content from "../img/ColorAndContrast_Content.png";
import Examples from "../img/ColorAndContrast_Examples.png";
import Insight from "../img/ColorAndContrast_Insight.png";


function ColorAndContrast(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <h1 style={{alignSelf: 'center'}} ><span className="highlight">Color & Contrast</span></h1>
      <a style={{textAlign: 'center'}} href="https://colorandcontrast.com/" target="_blank">Visit Color and Contrast.com &#8594;</a>

      <Image
          src={ColorAndContrastHero}
          alt="Screenshot of Color and contrast web application"            
        ></Image>
      <Section title="Overview">
        <p>Color & Contrast.com (<a href="https://colorandcontrast.com" target="_blank">https://colorandcontrast.com</a>) is an online interactive library for user interface designers to learn about color and contrast perception. It is intended to be simple and easy to understand, with interactive examples to aid in educating complex topics. Color & Contrast.com is a guide for beginning a journey into learning about visual perception and its affect on user interface design. </p>
        
        <h4>My role</h4>
        <p>Content creation and curation, design, and development.</p>

      </Section>

      <Section title="Context and inspiration">
        <p>A majority of my career has been working with color, and finding ways to create accessible color systems. Over the years, I've created accessible color systems for higher education (<Link to="/projects/ellucian">Ellucian design system</Link>) and for Adobe (<Link to="/projects/spectrum">Spectrum design system</Link>). These experiences led me to create the tool <Link to="/projects/leonardo">Leonardo</Link>, which provides access to color science as a tool in color palette creation.</p>
        <p>Over time, I began collecting my own resources on color and contrast perception. I learned primarily from optometry and color science text books, which can be difficult to understand.</p>
        <p>Simultaneously, I began noticing an influx of articles, tools, and statements from the design industry that promote falsehoods or misinterpretation of perception and science. Designers are becoming more interested in the science of visual perception, but fall victim to popular click-bait articles that only convolude the facts or obstruct their journey to understanding these concepts.</p>
        <p>This inspired me to release Color & Contrast.com as the go-to resource for nearly anything a designer needs to know, or would want to know, about color science, color perception, and contrast perception, along with various aspectso of vision to provide more detail about inclusive design.</p>

      </Section>

      <Section title="Digestible content">
        <p>A primary goal was to make the content accessible to designers. Every topic was written to be as concise and approachable as possible.</p>
        <Image
          src={Content}
          className="constrained"
          alt="Screenshot of Lateral inhibition definition"            
        ></Image>
      </Section>

      <Section title="Actionable insights">
        <p>Aside from learning these topics, designers need a clear understanding of how they affect user interface design. Certain topics are clear, whereas others may be very obscure. For this reason, each topic has actionable insights to help designers in their daily work.</p>
        <Image
          src={Insight}
          className="constrained"
          alt="Four insights listed for Lateral Inhibition and how it affects user interface design."            
        ></Image>
        
      </Section>

      <Section title="Interactive examples">
        <p>Most topics on Color & Contrast.com include interactive examples or demonstrations. These small tools provide a great way to learn beyond what the written word can accomplish. By interacting with these examples, sighted visitors can grasp a better sense of the topic and how it may affect interface design.</p>
        <Image
          src={Examples}
          className="constrained"
          alt="Whites illusion demonstrated with black and white bars with a midtone gray bar alternating each row."            
        ></Image>
        <Caption>White's illusion example with slider to separate or bring together the midtone gray bars, helpful in demonstrating the appearance of the gray tone based on its surroundings (due to lateral inhibition).</Caption>

      </Section>

      <Footer />

    </motion.div>
  )
}
 
export default ColorAndContrast;