import React from "react"
import './Video.scss';

class Video extends React.Component {
  // constructor(props) {
  //   super(props)
  // }
  render() {
    return (
      <video 
        controls="controls"
        name={this.props.name}
        className={this.props.className}
      >
        <source src={this.props.src}></source>
      </video>
    )
  }
}
 
export default Video;