import React from "react"
import './Badge.scss';

const Badge = (props) => {
    const type = props.type;
    const className = `badge badge--${type}`
    const content = (type === 'designSystem') ? 'Design system' :
        (type==='website') ? 'Web resource' :
        (type==='designTool') ? 'Design tool' : 'Null'

    return (
      <div className={className}>
        {content}
      </div>
    )

}
 
export default Badge;