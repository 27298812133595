import { 
  Link, 
  useParams 
} from 'react-router-dom';
import './Header.scss';

const HeaderLink = ({ page, selected }) => {
  const title = page.charAt(0).toUpperCase() + page.slice(1);
  let className = selected ? 'item selected ' : 'item ';
  let linkTo = (page === 'home') ? '' : page;


  return (
    <Link 
      to={`/${linkTo}`} 
      className={className} 
    >
      {title}
    </Link>
  );
};

const Header = () => {
  const page = useParams().page || 'home';

  return (
    <div className='navigation-sub'>
      <HeaderLink page='home' selected={page === 'home' || page === ''} />
      <HeaderLink page='projects' selected={page === 'projects'} />
      <HeaderLink page='articles' selected={page === 'articles'} />
      {/* <HeaderLink page='about' selected={page === 'about'} /> */}
      {/* <HeaderLink page='styleguide' selected={page === 'styleguide'} /> */}
    </div>
  );
};

export default Header;


