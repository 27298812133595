
import Behance from '../svg/Behance';
import Medium from '../svg/Medium';
import Twitter from '../svg/Twitter';
import Dribbble from '../svg/Dribbble';
import Github from '../svg/Github';
import LinkedIn from '../svg/LinkedIn';
import Vimeo from '../svg/Vimeo';

import './Footer.scss';

const Footer = () => {

  return (
    <footer className="App-footer">
      <div className="App-footer-content">
        <h2><span className="highlight">Get in touch</span></h2>
        <div className="socialLinks">
          {/* <a className="socialLink" href="https://www.behance.net/NateBaldwin" target="blank">
            <Behance className="socialIcon"/>
          </a> */}
          <a className="socialLink" href="https://twitter.com/NateBaldwinArt" target="blank">
            <Twitter className="socialIcon"/>
          </a>
          <a className="socialLink" href="http://www.linkedin.com/in/natebaldwindesign" target="blank">
            <LinkedIn className="socialIcon"/>
          </a>
          <a className="socialLink" href="https://medium.com/@NateBaldwin" target="blank">
            <Medium className="socialIcon"/>
          </a>
          <a className="socialLink" href="https://github.com/NateBaldwinDesign" target="blank">
            <Github className="socialIcon"/>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;