import React from "react"
import './Image.scss';

class Image extends React.Component {
  // constructor(props) {
  //   super(props)
  // }
  render() {
    return (
      <img 
        className={this.props.className}
        src={this.props.src}
        alt={this.props.alt}
      ></img>
    )
  }
}
 
export default Image;