import React from "react"
import ProjectCard from "./components/ProjectCard";
import LeonardoScreenshot from './img/Leonardo_Screenshot.png';
import StyleGuideMacbook from './img/EllucianStyleGuide_MacBook.png';
import PatternLibrary from './img/ExedePatternLibrary.png';
// import SpectrumScreenshot from './img/Spectrum_Screenshot.png';
import SpectrumScreenshot from './img/Spectrum_Hero.png';

import { BrowserRouter, Route, Link, useParams, useRouteMatch, Switch } from 'react-router-dom'; 
import { AnimatePresence, motion } from "framer-motion";
import Footer from './components/Footer';
import AdobeLogoMark from "./svg/AdobeLogoMark";

import {
  pageVariants,
  pageTransition,
  pageStyle
} from './components/Animation';
import IntuitLogoSquare from "./svg/IntuitLogoSquare";
import AdobeLogo from "./svg/AdobeLogo";
import EllucianLogoMark from "./svg/EllucianLogoMark";
import ViasatLogo from "./svg/ViasatLogo";
import Section from "./components/Section";
import Swatch from "./components/Swatch";
import Ramp from "./components/Ramp";
import Experience from "./components/Experience";
import ExperienceSmall from "./components/ExperienceSmall";

function Resume(props) {
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="resume">
        <section className="resume_Section">
          <h1 className="resume_Heading">Nate Baldwin</h1>
          <h2 className="resume_SubHeading">Principal Product Designer,<br/> Design Systems</h2>
        </section>
      </div>

      <div className="resume_details">
        <Section title="Experience">
            <div className="resume_details-section">
                <Experience
                    title="Principal Product Designer"
                    company="Intuit"
                    logo={<IntuitLogoSquare/>}
                    team="Intuit Design System"
                    startDate="Oct. 2022"
                    endDate="present"
                >
                    <p>Supported multiple brand-specific design systems teams relying on the Intuit Design System. Collaborated across business units to create a clear, flexible and scalable taxonomically-based token system.</p>
                    <h5>Responsibilities included:</h5>
                    <ul>
                        <li>Figma resources</li>
                        <li>Design token system</li>
                        <li>Documentation</li>
                        <li>Cross-functional collaboration</li>
                    </ul>

                </Experience>

                <Experience
                    title="Staff Product Designer"
                    company="Adobe"
                    logo={<AdobeLogoMark/>}
                    team="Spectrum Design System"
                    startDate="Oct. 2016"
                    endDate="Oct. 2022"
                >
                    <p>Created and documented foundations and component designs for a multi-platform design system across Adobe. Collaborated on product requirements and testing. Maintained design token system and strategized for improvements. Created custom tooling for generative and systems-oriented design.</p>
                    <h5>Responsibilities included:</h5>
                    <ul>
                        <li>Foundational systems design</li>
                        <li>Component design</li>
                        <li>Documentation</li>
                        <li>Design token system</li>
                        <li>Cross-functional collaboration</li>
                    </ul>
                    <Link className="button button-small" to="/spectrum">View work</Link>

                </Experience>

                <Experience
                    title="Sr. User Experience Designer"
                    company="Ellucian"
                    logo={<EllucianLogoMark/>}
                    team="Ellucian Design System"
                    startDate="Oct. 2016"
                    endDate="Oct. 2022"
                >
                    <p>Created Ellucian's first design system with support for design and engineering (CSS framework). Collaborated with engineering to optimize design to engineering workflows. </p>
                    <h5>Responsibilities included:</h5>
                    <ul>
                        <li>User research</li>
                        <li>Wireframing, prototyping, design</li>
                        <li>Documentation</li>
                        <li>Design token system</li>
                        <li>CSS framework</li>
                        <li>Sketch resources</li>
                    </ul>
                    <Link className="button button-small" to="/ellucian">View work</Link>

                </Experience>

                <Experience
                    title="Lead Graphic Designer / Art Director"
                    company="ViaSat"
                    logo={<ViasatLogo/>}
                    team="Exede Internet"
                    startDate="Sept. 2012"
                    endDate="July 2015"
                >
                    <p>Art directed and managed a small team of in-house and freelance designers. Created cohesive multi-channel marketing campaigns with distributed teams and agencies. </p>
                    {/* <h5>Responsibilities included:</h5>
                    <ul>
                        <li>User research</li>
                        <li>Wireframing, prototyping, design</li>
                        <li>Documentation</li>
                        <li>Design token system</li>
                        <li>CSS framework</li>
                        <li>Sketch resources</li>
                    </ul> */}
                    <Link className="button button-small" to="/exede">View work</Link>
                </Experience>
            </div>
        </Section>
        <div className="detail_column">
            <Section title="About me">
                <p>I am a systems-oriented designer, creating foundational concepts and systems that can scale. Design is a visual language, and the patterns we create reinforce our communication with other people. As an artist-turned-designer, I can navigate ambiguity and am familiar with abstract concepts. I am driven to advance design systems for creating cohesive, scalable, and accessible products.</p>
            </Section>

            <Section title="Education">
                <ExperienceSmall
                    title="BFA, Illustration"
                    institution="Rocky Mountain College of Art &amp; Design" 
                    year="2010"
                />

                <ExperienceSmall
                    title="Credentials, Fine Art"
                    institution="Central Wyoming College" 
                    year="2005"
                />
            </Section>

            <Section title="Patents">
                <ExperienceSmall
                    title="Contrast-Ratio-Based Color Generation"
                >
                    <dl>
                        <li>Issued May 5, 2020</li>
                        <li>Patent number <a href="https://image-ppubs.uspto.gov/dirsearch-public/print/downloadPdf/10643353" target="_blank" rel="noreferrer">US 10,643,353</a></li>
                    </dl>
                    <dl>
                        <li>Revision date Mar. 2, 2021</li>
                        <li>Patent number <a href="https://image-ppubs.uspto.gov/dirsearch-public/print/downloadPdf/10937204" target="_blank" rel="noreferrer">US 10,937,204</a></li>
                    </dl>
                </ExperienceSmall>
            </Section>
        </div>
      </div>


      <Footer />
    </motion.div>
  )
}

export default Resume;
