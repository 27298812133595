import React from "react"
import './Experience.scss';

const Experience = (props) => {
    const title = props.title;
    const company = props.company;
    const team = props.team;
    const startDate = props.startDate;
    const endDate = props.endDate;
    const year = props.year;
    const experienceDate = (startDate && endDate) ? <span className="Experience-Date">{startDate} — {endDate}</span> : ' ';

    const detail = (team) ? `${company}, ${team}` : `${company}`

    return (
        <div className="Experience-Wrapper">
            <div className="Experience-Logo">
                {props.logo}
            </div>
            <article className="Experience">
                <div className="Experience-Header">
                    <h5><span>{title}</span> <span>{year}</span></h5>
                    <div className="Experience-SubHeader">
                        <span className="Experience-Company">{team}</span>
                        {experienceDate}
                    </div>
                </div>
                <div className="Experience-Details">
                    {props.children}
                </div>
            </article>
        </div>

    )
  
}
 
export default Experience;