import React from "react"

import Image from "../components/Image";
import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { AnimatePresence, motion } from "framer-motion";
import Footer from '../components/Footer';
import Caption from '../components/Caption';

import LeonardoScreenshot from '../img/Leonardo_Screenshot.png';
import ContrastSwatches from '../img/Contrast_Swatches.png';
import LeoTheme from '../img/Leo_Theme.png';
import LeoHero from '../img/Leo_Hero.png';
import LeoTokens from '../img/Leo_Tokens.png';
import LeoScales from '../img/Leo_Scales.png';
import LeoCompare from '../img/Leo_Compare.png';
import LeoConvert from '../img/Leo_Convert.png';
import LeoQual from '../img/Leo_Qual.png';
import LeoVisualizations from '../img/Leo_Visualizations.png';
import Charts from '../img/Charts@2x.png';
import LeonardoThemeCVDPreview from '../img/Theme-CVD-sim.png';

const videoWidth = (window.innerWidth < 864) ? window.innerWidth - 64 : `800`;
const videoHeight = videoWidth / 1.638;

console.log(videoWidth, videoHeight)

function Leonardo(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <h1 style={{alignSelf: 'center'}} ><span className="highlight">Leonardo</span></h1>
      <a style={{textAlign: 'center'}} href="https://leonardocolor.io/" target="_blank">Visit Leonardo &#8594;</a>
      <Image
          src={LeoHero}
          alt="Screenshot of Leonardo web application"            
        ></Image>
      <Section title="Overview">
        <p>Leonardo is an open source tool for creating adaptive color palettes; a custom color generator for creating colors based on target contrast ratio. Leonardo is delivered as a <a href="https://www.npmjs.com/package/@adobe/leonardo-contrast-colors" target="blank">Javascript module</a> (<code>@adobe/leonardo-contrast-colors</code>) with a <a href="https://leonardocolor.io" target="blank">web interface</a> to aid in creating your color palette configurations, which can easily be shared with both designers and engineers. Simply put, Leonardo is for dynamic accessibility of your products.</p>
        
        <h4>My role</h4>
        <p>Designed and build the web application and underlying npm module. Primary maintainer of the Leonardo open source project.</p>

      </Section>

      <Section title="Context">
        <p>Creation of color palettes for digital products is a tricky process, especially when taking into consideration the multitude of sighted users who will interact it. The Web Content Accessibility Guidelines (WCAG) has created a set of basic criteria for digital products to be considered accessible; the most pertinent of these criterias are those of color contrast minimums.</p>
        <p>The way designers have historically created accessible color palettes has been a process of selecting a color, checking it’s contrast with a background, and then re-adjusting the color. This repeated process is time consuming and makes the process of creating a holistically cohesive color palette more complex.</p>
        <p>For Adobe’s design system, Spectrum, we wanted to turn the problem on its head. Rather than audit colors to meet contrast requirements, why not define a continuous color scale and generate individual colors by desired contrast ratios?</p>
        <Image
          src={ContrastSwatches}
          className="constrained"
          alt="Screenshot of contrast ratio swatches in Leonardo"            
        ></Image>
        <Caption>Synchronized inputs for target contrast ratio and lightness with real-time accessibility feedback.</Caption>

      </Section>

      <Section title="Adaptive theming">
        <p>Leonardo has since grown into a more robust tool for creating and evaluating color themes. Themes can be easily modified for universally increasing contrast, decreasing saturation, or changing the brightness of the theme altogether. This makes dark mode support easier to achieve than ever before.</p>
        {/* <Image
          src={LeoTheme}
          className="constrained"
          alt="Screenshot of theme in Leonardo"            
        ></Image> */}
        <iframe 
          width={videoWidth} 
          height={videoHeight}
          className="constrained"
          src="https://www.youtube.com/embed/d-i18M384Bo" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen>
        </iframe>
        <Caption>Walk-through demonstration of creating an adaptive color theme in Leonardo.</Caption>

        <p>Visualizations in Leonardo help designers who want to evaluate perceptual balance of color. Leonardo provides visualizations for all supported color spaces as a way to encourage exploration and learning. Some color spaces are better than others for certian things, but there is no one color space that outweighs them all. Leonardo aims to provide designers insight into this fact with visualizations and examples as they edit their colors.</p>
        <Image
          src={LeoVisualizations}
          className="constrained"
          alt="Color wheel and 2d charts of color theme"            
        ></Image>
        <Caption>Charts to evaluate color harmonies, hue, and saturation relationships acoss an entire theme.</Caption>

        <p>Leonardo also provides resources for engineers. Themes output the parameters needed for the <code>@adobe/leonardo-contrast-colors</code> package. Alternatively, engineers can copy CSS properties or Design Tokens directly from the web app.</p>
        <Image
          src={LeoTokens}
          className="constrained"
          alt="Design tokens output in Leonardo"            
        ></Image>
        <Caption>Auto-generated design tokens with descriptions about a color's contrast and accessible usage.</Caption>
      </Section>

      <Section title="Data visualization color scales">
        <p>Leonardo has a dedicated workspace for creating sequential, diverging, and qualitative (categorical) color scales for data visualization. The sequential and diverging scales have a table reporting on the contrast accessibility of the colors.</p>
        {/* <Image
          src={LeoScales}
          className="constrained"
          alt="Sequential scale editor in Leonardo"            
        ></Image> */}
        <iframe 
          width={videoWidth}
          height={videoHeight}
          className="constrained"
          src="https://www.youtube.com/embed/3xxrsF5tYEE" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen></iframe>
        <Caption>Walk-through demonstration of creating a colorblind safe palette from an existing set of colors.</Caption>

        <p>For qualitative scales, Leonardo generates accessible combinations of colors. This feature provides constraints for you to define the threshold of color difference and colorblindness types that you want to support. As you identify colors you want to include in your palette, they are added and "locked" to the palette. This enables you to cycle through possible combinations of colorblind safe colors to pick the best colors for your needs.</p>
        <Image
          src={LeoQual}
          className="constrained"
          alt="Qualitative color scale editor in Leonardo"            
        ></Image>
        <Caption>Leonardo's one-of-a-kind generation of accessible color combinations based on accessibility constraints.</Caption>
      </Section>

      {/* <Section title="Color vision deficiency simulation">
        <p>Leonardo themes can be evaluated for color vision deficiencies (CVD, aka 'color blindnesses') using built-in simulations. Colors are modified in the web interface to display as they may appear for various CVD's for simpler theme-wide color evaluation.</p>
        <Image
          src={LeonardoThemeCVDPreview}
          alt="Leonardo theme tab displaying colors in a Protanopia simulation"            
        ></Image>
      </Section> */}

      <Section title="Additional tools">
        <p>The web interface also includes a few small-yet-handy tools for designers. The first is a conversion tool so that users can easily convert color values between Hex, RGB, LAB, LCH, HSL, HSV, HSLuv, CIECAM02, CIECAM02-UCS, XYZ, OKLAB, and OKLCH, color spaces. It also finds the closest matching Pantone color.</p>
        <Image
          src={LeoConvert}
          className="constrained"
          alt="Bulk conversion dialog with checkboxes for each color space"            
        ></Image>
        <Caption>Bulk conversion enables designers to continue color analysis in other tools like Excel.</Caption>

        <p>Leonardo also provides a color comparison tool. Leveraging the same w3c relative luminance formula (used for WCAG criteria and for generating colors in Leonardo), the tool exposes a simple foreground/background contrast checker. <b>Unlike many other tools, Leonardo’s contrast checker includes an alpha channel </b>so that you can identify what levels of opacity a particular color must be in order to meet contrast requirements.</p>
        <p>Along with contrast, the comparison tool provides daltonized simulations for colorblindness types, as well as the calculated color difference for each type. This helps to verify how similar or different a color will appear to various color vision deficiencies.</p>
        <Image
          src={LeoCompare}
          className="constrained"
          alt="Leonardo comparison tool with green and blue colors with 3.44 contrast ratio"            
        ></Image>
        <Caption>A robust color comparison view provides WCAG compliance reporting and color difference for colorblindness.</Caption>

      </Section>


      <Section title="Open source npm module">
        <p>Accessibility is not just an Adobe problem, so we wanted to ensure Leonardo was made available to anyone who needs it, <b>for free</b>. The decision to build open-source helped steer us towards using existing open-source libraries such as chroma.js for color conversions and d3 for in-tool visualizations.</p>
        {/* <Image
          src={Charts}
          alt="Charts displaying color channels in CIECAM02 color space"            
        ></Image> */}
      </Section>


      <Section title="Leonardo in use">
        <p>Leonardo has been a game-changer for products that allow for custom theming. One client uses Leonardo to pass brand colors for 3,000+ brands in order to create unique, on-brand, and fully accessible color palettes on the fly. Previously, this scale of color accessibility efforts would have been constrained to self-service documentation and auditing. Leonardo has automated this process for them with a guaranteed WCAG compliant output.</p>
      </Section>


      <Section title="Writing the tool">
        <p>Several articles have been written in order to better introduce Leonardo, colorimetry, and the concept of adaptive color palettes, which were all inspired by our work creating Leonardo. These articles help to further illustrate the depth of our journey into color science and creating a feature-rich tool for designers and engineers to author adaptive color palettes for their digital products.</p>

        <h4>Articles</h4>
        <ul>
          <li>
            <b><a href="https://medium.com/@NateBaldwin/accessible-color-for-design-systems-just-got-easier-40e8420a8371">Accessible color for design systems just got easier</a></b>
          </li>
          <li>
            <b><a href="https://medium.com/@NateBaldwin/color-scales-for-data-visualization-in-leonardo-bf206feb61b9">Color scales for data visualization in Leonardo</a></b>
          </li>

          <li>
            <a href="https://medium.com/thinking-design/adaptive-color-in-design-systems-7bcd2e664fa0">Adaptive Color in Design Systems (part one)</a>
          </li>
          <li>
            <a href="https://medium.com/@NateBaldwin/introducing-adaptive-color-palettes-111b5842fc88">Introducing Adaptive Color Palettes (part two)</a>
          </li>
          <li>
            <a href="https://medium.com/@NateBaldwin/adaptive-color-in-spectrum-adobes-design-system-feeeec89a2c7">Adaptive Color in Spectrum, Adobe’s Design System (part three)</a>
          </li>
          <li>
            <a href="https://medium.com/@NateBaldwin/leonardo-an-open-source-contrast-based-color-generator-92d61b6521d2">Leonardo: an open source contrast-based color generator</a>
          </li>
          <li>
            <a href="https://medium.com/@NateBaldwin/creating-contrast-based-themes-with-leonardo-32b6219a090f">Creating contrast-based themes with Leonardo</a>
          </li>
          <li>
            <a href="https://medium.com/@NateBaldwin/colorimetry-and-the-cartography-of-color-415ef5315c0a">Colorimetry and the Cartography of Color</a>
          </li>
        </ul>
      </Section>

      <Section title="Additional links">
        <ul>
          <li>
            <a href="https://leonardocolor.io/">Leonardocolor.io</a>
          </li>
          <li>
            <a href="https://github.com/adobe/leonardo">Leonardo on Github</a>
          </li>
          <li>
            <a href="https://www.npmjs.com/package/@adobe/leonardo-contrast-colors">Leonardo NPM package</a>
          </li>
        </ul>
      </Section>

      <Footer />

    </motion.div>
  )
}
 
export default Leonardo;