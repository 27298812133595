import * as React from "react"

function Vimeo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 432 484" {...props}>
      <path
        fill="#FFF"
        d="M353.199 73.593c-54.052-1.763-90.629 28.555-109.78 90.992 9.863-4.061 19.449-6.113 28.797-6.113 19.708 0 28.434 11.064 26.106 33.247-1.158 13.431-9.863 32.975-26.106 58.627-16.308 25.691-28.486 38.517-36.577 38.517-10.506 0-20.066-19.85-28.797-59.499-2.929-11.677-8.16-41.412-15.66-89.277-7.029-44.332-25.6-65.046-55.797-62.135-12.774 1.171-31.912 12.865-57.452 35.009a4985.96 4985.96 0 01-56.7 50.769l18.282 23.604c17.448-12.26 27.657-18.36 30.495-18.36 13.375 0 25.877 20.987 37.498 62.96 10.428 38.547 20.935 77.043 31.406 115.534 15.6 42.003 34.828 63.012 57.452 63.012 36.586 0 81.38-34.409 134.205-103.248 51.106-65.906 77.492-117.832 79.272-155.788 2.345-50.725-16.533-76.701-56.644-77.851z"
      />
    </svg>
  )
}

export default Vimeo
