import React from "react"

import Image from "../components/Image";
import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { AnimatePresence, motion } from "framer-motion";
import Footer from '../components/Footer';
import Caption from '../components/Caption';

import EllucianHero from '../img/Ellucian_Hero.png';
import SketchLibrary from '../img/Ellucian_Sketch_Symbol_Library_02.jpg';
import TaxomomyGraph from '../img/CardSortGraph.png';
import SassArchitecture from '../img/Standard-SASS-Architecture.jpg';
import SCSSStandards from '../img/SCSS-standards-example.png';

function Ellucian(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <h1 style={{alignSelf: 'center'}} ><span className="highlight">Ellucian design system</span></h1>
      <Image
          src={EllucianHero}
          alt="Ellucian style guide webpage on macbook pro"            
        ></Image>

      <Section title="Overview">
        <p>In order to unify a cross-platform product suite, we created a shared set of tools for designers and developers. Automation was leveraged to generate assets for multiple platforms.</p>
        
        <h4>My role</h4>
        <p>User and technology researcher, lead designer and web developer for CSS framework and documentation.</p>

      </Section>

      <Section title="User and technology research">
        <p>The Design System needed to accommodate ~70 different products across a variety of different web technologies and native mobile application platforms. Interviews were conducted with various developers and product owners for products that represented the core technologies and keystone products in our suite. The interviews gave insight into the technology stacks to find commonalities, differences, and any restrictions they have to using a common design system.</p>

        <p>The interviews allowed us to create proto-personas of our internal users. This revealed to us the key motivators and hurdles that existed for our development teams. </p>
      </Section>

      <Section title="Taxonomy research">
        <p>The Design System needed to use a common language for both designers and developers. Our design team used an atomic design approach to their work, but we needed to verify a familiar taxonomy for everyone.</p>
      
        <p>An open card sort exersize was done with a group of designers, developers, architects and product owners. The task was for the individuals to take common, existing UI patterns and group them into categories. The individuals gave their groups names per their own intuition and experiences. The resulting data was analyzed and used to create an intuitive taxonomy for categorizing the UI patterns in the Style Guide.</p>
        <Image
          src={TaxomomyGraph}
          className="constrained"
          alt='Chart showing similarity matrix of terms for naming components'
        ></Image>
        <Caption>Charts from the output of a naming exercise provided insight into existing mental models for clear compnoent naming.</Caption>
      </Section>

      <Section title="Development standards">
        <p>There was no clear set of strict naming conventions or architecture for CSS, and we recognized the impact that standards have on a unified design system. We adopted and incorporated a BEM naming convention, SMACSS property ordering, and ITCSS architecture to all our stylesheets. The system was built with SCSS to utilize variables and Mixins, as well as to modularize our stylesheets for easy maintenance and organization. These and other standards were researched and tested prior to implementation. The result was a scannable, searchable, intuitive set of SCSS stylesheets.</p>
        <Image
          src={SCSSStandards}
          className="constrained"
          alt='Chart showing similarity matrix of terms for naming components'
        ></Image>
        <Caption>SCSS Standards documented in the CSS framework source code.</Caption>
      </Section>

      <Section title="Sketch symbol library">
       <p>All of our standard UI patterns were put into a single UI kit, or Master Design Template. Symbols were created at all major breakpoints and categorized using the taxonomy from our research. The UI kit provided a single unified resource for our global team of designers, and was critical to the successful adoption of the standard design and UI patterns.</p>
        <Image
          src={SketchLibrary}
          className="constrained"
          alt="Sketch symbols with all component permutations displayed on artboards"
        ></Image>
        <Caption>Sketch symbol library of all components and styles.</Caption>
      </Section>

      <Section title="The design system">
        <p>The design system was built using SCSS, Gulp, and Node (to name a few resources). Each UI pattern was built using an I functional HTML shell in order do render the CSS in browser. These shells would later become the beginning code for developing a core set of shared, fully functional components. The UI patterns were developed to match the standard designs from the Sketch Symbol Library.</p>

        <p>Iconography was implemented using an inline SVG Sprite, which is generated using Gulp. This allowed for highly customizable icons that are easy to use and have great browser support.</p>

        <p>The design system is governed by a core group from the UX team and a development team. This group was assembled to ensure every asset was production-ready and future-forward.</p>
        <Image
          src={SassArchitecture}
          className="constrained"
          alt='Flow chart for pipeline from user defined theme elements (colors and fonts) through the dev pipeline to the distributed assets'
        ></Image>
        <Caption>Diagram of the dependency chain and process for end-user theming of the design system.</Caption>
      </Section>

      <Section title="Documentation site">
        <p>The design system needed extensive documentation. The documentation was written to be straightforward and clear, with a touch of familiarity. The Style Guide also serves as a platform for displaying all of the latest standard UI Patterns in a live environment, with additional notes regarding implementation as well as code snippets for reference. The patterns are also documented with additional notes regarding internationalization, localization, and accessibility. For UI patterns that have complex interactions, or subtle animated transitions documented in the specifications, animated prototypes are displayed alongside the patterns.  This allows developers to see the final implementation of the UI patterns and their behaviors.</p>

        <p>The style guide website was developed using Bootstrap, SCSS, Gulp, and Pug. It’s stylesheet are dependent upon the Ellucian Design System, making the resource adapt and evolve as the Design System scales and changes.</p>

        <p>The Ellucian Style Guide has been featured as an example in the resources at http://www.styleguides.io and has been referenced in articles by influential leaders in user experience design.</p>
      </Section>

      <Footer />

    </motion.div>
  )
}
 
export default Ellucian;