import React from "react"
import Image from "./Image";
import { Link } from 'react-router-dom'; 
import './ArticleCard.scss';

class ArticleCard extends React.Component {
  // constructor(props) {
  //   super(props)
  // }
  render() {
    return (
      <div className="ArticleCard">
        <div className="ArticleCard_details">
          <a href={this.props.link} target="blank">
            <h3><span className="highlight">{this.props.title}</span></h3>
            <p className="articleDetail">Published on {this.props.publisher}</p>
            <p>{this.props.description}</p>
          </a>
        </div>
        {/* <a href={this.props.link} target="blank"> */}
        <div className="ArticleCard_Image">
          <Image src={this.props.image} alt={this.props.alt}></Image>
        </div>
        {/* </a> */}
      </div>
    )
  }
}
 
export default ArticleCard;