import React from "react"
 
class Swatch extends React.Component {
  constructor(props) {
    super(props)
    this.color = props.color;
    this.name = props.name;
    this.contrast = props.contrast;
    this.textColor = (this.contrast < 4.5) ? '#000000' : '#ffffff';
  }

  render() {
    return (
      <div className="colorSwatch" style={{ backgroundColor: `var(--${this.name})` }}>
        {/* <span class="colorName">{this.name}</span> */}
        <div className="swatchContrastValue">
          <span 
            className="colorContrast"
            style={{color: `${this.textColor}`}}
          >
            {this.contrast}</span>
          <span
            style={{color: `${this.textColor}`}}
            >
            :1</span>
        </div>

        <div className="swatchColorValue" style={{color: `${this.textColor}`}}> {this.color} </div>
      </div>
    )
  }
}
 
export default Swatch;
