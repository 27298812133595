import React from "react"

import Image from "../components/Image";
import Video from "../components/Video";
import Section from "../components/Section";
import Caption from '../components/Caption';
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { AnimatePresence, motion } from "framer-motion";

import IntuitDesignSystemHero from '../img/IDS_Hero.png';
import IDSTaxonomy from '../img/IDS_Taxonomy.png';


import Footer from '../components/Footer';

function IntuitDesignSystem(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <h1 style={{alignSelf: 'center'}} ><span className="highlight">Intuit design system</span></h1>
      <Image
          src={IntuitDesignSystemHero}
          alt="Screenshot Figma tokens and the Intuit Design Systems documentation website"            
        ></Image>
      <Section title="Overview">
        <p>The Intuit Design System (IDS) is at the core of the Intuit product ecosystem. IDS supports products directly, as well as product-specific design systems for Quickbooks, TurboTax, Mailchimp, and CreditKarma. IDS provides foundational design elements and components, and acts as a platform for creating or maintaining design systems at Intuit.</p>
        
        <h4>My role</h4>
        <p>Direction and strategy for design tokens, lead designer for creating framework to support evolutionary multi-brand theming framework.</p>
      </Section>

      <Section title="Design token system">
        <h4>Problem</h4>
        <p>When I joined Intuit, IDS relied primarily on component-level tokens. The problem our consumers had was that it was difficult to identify when and where tokens could be used outside their specific use case, and there were too many tokens to reasonably navigate. This made token management and theming a tedious and difficult task for designers and developers.</p>

        <h4>Solution</h4>
        <p>I led cross-functional collaborative workshops with stakeholders in each product team to construct a formalized taxonomy system for IDS tokens. A formalized taxonomy would ensure cohesion, consistency, and scalability of the system as we identify future use cases.</p>

        {/* <Image
          src={IDSTaxonomy}
          className="constrained"
          alt="Illustration of several taxonomies used in the IDS token system"            
        ></Image> */}

        <p>Each token type had their own defined terms and applicable taxonomies, so to keep our taxonomy system well-defined without adding complexity to the resulting token names.</p>

        <Image
          src={IDSTaxonomy}
          className="constrained"
          alt="Illustration of several taxonomies used in the IDS token system"            
        ></Image>

        <p>Complex token sets (such as color) were tested with users to find terms that were comprehensible and intuitive.</p>

        <p>As design lead for IDS tokens, I provide continuous education and support for consumers, and iteratively evolve our set to meet the needs of our complex product ecosystem.</p>

        {/* <p>One example of educational outreach was my contribution to the Quickbooks Design System team's "Design Systems Snacks" video series. These were short, Instagram-like videos shared across Slack channels for quick summaries of different aspects of our systems:</p>
        <Video 
          src={DSsnacks}
          className="small"
          name="Whats in a token name"
        ></Video> */}
      </Section>

      <Section title="Theming framework">
        <h4>Problem</h4>
        <p>For designers and developers who create and manage themes were unable to accomplish their goals within their team’s timelines. Theming was undocumented, guidance was unclear, and teams had to navigate a complex token system to make updates. They also had to rely on the IDS team to update components and tokens to add new flexibility to meet their desired goals.</p>

        <p>For the business, misaligned goals and unclear expectations around theming put us at risk of being unable to meet anyone’s goals. This could have resulted in product deceleration, design stagnation, and fragmentation from a central design system.</p>

        <h4>Solution</h4>
        <h6>Vision & strategy</h6>
        <p>The strategy is to enable bold, rapid innovation and product expression rooted in cohesive, inclusive, and accessible standards. </p>

        <p>The concept for our solution was called “Progressive theming”. It is a method for providing a simple theming experience that supports turn-key and highly custom theming of the Intuit design system. This solution is accomplished using (and enhancing) our design tokens with system-wide constraints, and implementing tooling that provides token automations and inheritance. </p>
        
        <div className="YouTube_Embed">
          <iframe className="YouTubeVideo" src="https://www.youtube.com/embed/Id0iNZTrz1k?si=x84z28galnZo9CeR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>

        <p>This "sizzle reel" was created as part of a larger presentation for stakeholders to pitch Progressive theming. It was created to get teams excited about a potential future state, even though the details of the framework were undefined. This provided the momentum required to begin implementing a system that would enable <strong>systematic and scalable</strong> governance of a theming framework.</p>
{/* 
        <h6>Design</h6>
        <p>...</p> */}

      </Section>

      <Footer />

    </motion.div>
  )
}
 
export default IntuitDesignSystem;