import * as React from "react"

function Contrast(props) {
  return (
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5 21.5C14.8869 21.5 17.1761 20.5518 18.864 18.864C20.5518 17.1761 21.5 14.8869 21.5 12.5C21.5 10.1131 20.5518 7.82387 18.864 6.13604C17.1761 4.44821 14.8869 3.5 12.5 3.5C10.1131 3.5 7.82387 4.44821 6.13604 6.13604C4.44821 7.82387 3.5 10.1131 3.5 12.5C3.5 14.8869 4.44821 17.1761 6.13604 18.864C7.82387 20.5518 10.1131 21.5 12.5 21.5Z" stroke="var(--gray800)" strokeWidth="2.39583" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.5 12.5C12.5 16.0028 12.5 18.8424 12.5 18.8424C8.99718 18.8424 6.15758 16.0028 6.15758 12.5C6.15758 8.99718 8.99718 6.15758 12.5 6.15758C12.5 6.15758 12.5 8.99718 12.5 12.5Z" fill="var(--gray800)"/>
</svg>



  )
}

export default Contrast