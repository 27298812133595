import * as React from "react"

function Medium(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1633.8 1150.5"
      {...props}
    >
      <path d="M883.5 576.3c0 163.7-131.8 296.3-294.3 296.3S294.8 739.9 294.8 576.3s131.8-296.4 294.3-296.4 294.3 132.7 294.4 296.4M1206.3 576.3c0 154.1-65.9 279-147.2 279S912 730.3 912 576.3s65.9-279 147.2-279 147.1 124.9 147.1 279M1338.4 576.3c0 138-23.2 249.9-51.8 249.9s-51.8-111.9-51.8-249.9 23.2-249.9 51.8-249.9 51.8 111.8 51.8 249.9" />
      <path
        d="M1633.8 0h-296.3v.2H296.3V0H0v1150.1h119.5v.4h1410.4v-.4h103.8V0zm-296.3 296.5v557.7H296.3V296.5h1041.2z"
        fill="none"
      />
    </svg>  )
}

export default Medium
