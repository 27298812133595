import React from "react"
import './Caption.scss';

class Caption extends React.Component {
  // constructor(props) {
  //   super(props)
  // }
  render() {
    return (
      <span className="caption"> {this.props.children} </span>
    )
  }
}
 
export default Caption;