import * as React from "react"

function Saturation(props) {
  return (
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-inside-1_15_930" fill="white">
<rect x="1.5" y="5.5" width="22" height="14" rx="2"/>
</mask>
<rect x="1.5" y="5.5" width="22" height="14" rx="2" fill="url(#paint0_linear_15_930)" stroke="var(--gray800)" strokeWidth="4.8" mask="url(#path-1-inside-1_15_930)"/>
<defs>
<linearGradient id="paint0_linear_15_930" x1="23.5" y1="12.5" x2="1.5" y2="12.5" gradientUnits="userSpaceOnUse">
<stop stopColor="var(--burntOrange200)"/>
<stop offset="1" stopColor="var(--gray500)"/>
</linearGradient>
</defs>
</svg>

  )
}

export default Saturation