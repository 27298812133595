import React from "react"
 
class Ramp extends React.Component {
  constructor(props) {
    super(props)
    this.colors = props.colors;
  }

  render() {
    return (
      <div className="colorRamp" style={{ background: `linear-gradient(to right, ${this.colors})` }}>
      </div>
    )
  }
}
 
export default Ramp;
