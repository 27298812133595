import React from "react"

import Image from "../components/Image";
import Video from "../components/Video";
import Section from "../components/Section";
import Caption from '../components/Caption';
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import { AnimatePresence, motion } from "framer-motion";

import SpectrumHero from '../img/Spectrum_Hero.png';
import ComponentStatus from '../img/Spectrum-PM-ComponentStatus.png';
import TokenWiki from '../img/Spectrum-Tokens-ObjectStyles.png';
import TokenNamingConvention from '../img/Spectrum-Tokens-NamingConvention.png';
import Specs from '../img/Spectrum-Tokens-Specs.png';
import Manifest from '../img/Spectrum-Tokens-Manifest.png';
import SpTypography from '../img/Spectrum-Typography.png';
import SpSpacing from '../img/Spectrum-Spacing.png';
import DesignAPI from '../img/Spectrum-DesignAPI.png';
import WHCMCheckbox from '../img/Spectrum-WHCM-Checkbox.png';
import SpDocumentation from "../img/Spectrum-Documentation.png";
import SpInternalDocumentation from "../img/Spectrum-InternalDocumentation.png";
import TreeView from "../img/Spectrum-TreeView.png";

import VideoUiIconPrototype from '../video/UI-Icon-prototype-tool.mp4'
import VideoAdaptiveTheme from '../video/AdaptiveTheme.mp4'
import VideoListItem from '../video/ListItem-prototype.mp4'

import Footer from '../components/Footer';

function Spectrum(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <h1 style={{alignSelf: 'center'}} ><span className="highlight">Spectrum design system</span></h1>
      <Image
          src={SpectrumHero}
          alt="Screenshot design token codebase, an Xd UI kit, and documentation on Spectrum's website"            
        ></Image>
      <Section title="Overview">
        <p>The Spectrum design system is aimed toward the unification of an entire enterprise-level cross-platform product suite for the Creative Cloud, Document Cloud, Experience Cloud, and other products extending into Adobe's third-party developer ecosystem. Spectrum is a single-source-of-truth for design tokens, artifacts, components, documentation, and more.</p>
        
        <h4>My role</h4>
        <p>The majority of my tenure at Adobe has been spent building, supporting, and evolving the Spectrum design system. Over the years, I have taken on many different roles that have been critical in its growth and success.</p>

        <h5>Primary roles and responsibilities:</h5>
        <ul>
          <li>Product management</li>
          <li>Design tokens lead</li>
          {/* <li>Formalizing design language system</li> */}
          <li>Foundational systems design</li>
          <li>Component design</li>
          <li>Design prototyping</li>
          <li>Documentation</li>
          <li>Framework partnerships</li>
        </ul>
      </Section>

      <Section title="Product management">
        <p>I fulfilled this role during the beginning phase of Spectrum's formalization. The system already had a set of distinct, yet dispirate resources used by various product teams. My objectives were to identify the key areas of opportunity to help drive product success with Spectrum. This included gathering requirements from product teams, evaluating the landscape of framework and technology support, and identifying direction for the Spectrum product. </p>
        <p>I was able to provide insight into the tech landscape and identify core frameworks teams to support for the biggest early impact for Spectrum. I created mechanisms in Jira and Confluence for tracking the process and status for multi-platform component implementation. These mechanisms were enhanced to create a view of component usage in a handful of high profile products. This helped to give a view of which components were most used, where they were used, and their status regarding implementation over time.</p>

        <Image
          src={ComponentStatus}
          className="constrained"
          alt="Table of component statuses for Spectrum components across multiple frameworks"            
        ></Image>
        <Caption>Component statuses captured in a wiki for tracking implementation and reviews.</Caption>

      </Section>

      <Section title="Design tokens lead">
        <h5>Tokens strategy</h5>
        <p>I directed the strategy for tokens that grew into a systems-oriented authroing environment, where conditional logic and inheritance was a direct representation of design intent. This led into innovative solutions and ensured stability in this strategic approach for our consumers.</p>
        <Image
          src={Manifest}
          className="constrained"
          // alt="Table of component statuses for Spectrum components across multiple frameworks"            
        ></Image>
        <Caption>A JSON file defines Action button's API. The template file is for authoring tokens and captures conditional values based on any of the API options for the component.</Caption>

        <h5>Token naming and standards</h5>
        <p>Standardized names for common component attributes, with specifications on how they relate to component design. This ensured a consistent naming convention for tokens, as well as clear expectations for engineers regarding what a token is relative to.</p>
        <p>A primary goal was to standardize on an agnostic naming convention for the parts of a component, that way we had a unified or common language when speaking with engineers on any team. The mapping of these attributes to platform-specific attributes along with visual aids helped resolve questions about how they related.</p>
        <Image
          src={TokenWiki}
          className="constrained"
          alt="Object style tokens defined with respective platform attributes and a visual aid"            
        ></Image>
        <Caption>Design token wiki defined core "attributes" and relative information for each platform.</Caption>

        <p>Formalized a naming convention and data structure for design tokens. Tokens were expressed in flat variable names and structured JSON with a consistent hierarchical approach.</p> 
        <p>The naming convention was enforced via code within the design token system. Any of the standard API options used in a component would be automatically sorted to the proper order, and states were appended to the end of tokens as specified in our standard. This provided ability to generate tokens, automate visual regression testing, and generate styles for component implementations.</p>
        <p>Component tokens were not hand-written. Attributes were written with data mapping their token use based on API properties, and the tokens would be automatically generated within the tool itself. This made component-level token authoring a simple process focused on mapping styles without the added worry of conforming to the standard naming conventions.</p>
        <Image
          src={TokenNamingConvention}
          // className="constrained"
          alt="Object style tokens defined with respective platform attributes and a visual aid"            
        ></Image>
        <Caption>Naming conventions were documented in a wiki, but enforced through code in our token system.</Caption>
        
        <h5>Tokens documentation, implementation, and review</h5>
        <p>Created detailed specifications for components to assist in token authoring and token consumption. These specification files illustrated every component-level token for the component, any related aliases they used, the core (or "global") tokens, and an example token value for the default color theme and platform scale.</p>
        <p>Specificity in these files was necessary because at the time there was no sufficient support for specs and design tokens in UI design tools. This also provided a high degree of detail in the information we captured, which helped teams support multiple themes and platforms, which secondary design inspection tools could not properly surface.</p>
        <Image
          src={Specs}
          // className="constrained"
          alt="Xd files with annotated tables for attributes of the component"            
        ></Image>
        <Caption>Detailed specifications provided values and tokens for each component in every theme and each platform scale.</Caption>
        <p>I worked in collaboration with frameworks teams on iOS, Android, UWP (Windows), CSS, React, Web-Components, and several internal frameworks to implement Spectrum components. This included gathering requirements for tokens, fixing and evolving the data in design tokens, and reviewing individual component implementations for proper token usage.</p>
        <p>Early work consisted of evaluating screenshots of every component variation in each interactive state for every supported theme and platform. However, I worked with teams to automate this process as much as possible. Builds from each library automatically exported these screenshots. Once a component was approved, the screenshots from the approved build could be used for visual regression testing.</p>
      </Section>

      <Section title="Foundational systems design">
        <p>Designed and played a key role in authoring foundational design systems such as <b>typography</b>, <b>color</b>, <b>spacing</b>, and component (UI) <b>iconography</b>. I also worked on the creation and evolution of many other sub-systems implemented in our components, such as <b>selection</b> styling and interaction behavior, a <b>system for common component attributes</b> such as size, spacing, padding, and responsive behavior for all list-like components.</p>

        <h5>Typography system</h5>
        <p>Defined a typographic scale with type-relative margin sizes for long-form text in order to ensure proper balance and visual rhythm of typography. This included size pairing recommendations for creating hierarchical content.</p>
        <Image
          src={SpTypography}
          className="constrained"
          alt="Parings of typography for detail, heading, and body styles for sizes XL, L, M, and S."            
        ></Image>
        
        <h5>Icon scaling system</h5>
        <p>Created a system for scaling our component icons (UI icons). These icons are specifically used in the design of components themselves, such as chevrons for pickers, and checkmarks for check boxes. We wanted to ensure that these icons scaled in size proportionally to the type scale. However, stroke width for icons needed scale at a different rate in order to hold an appropriate visual weight when compared to accompanying text.</p>
        <p>I created a prototype to evaluate and define the system that scales icon strokes at a proportionally different rate than the icons scale themselves. This enabled us to refine icon shapes systematically, accounting for a proper visual weight as icons changed in size.</p>
        <Video 
          src={VideoUiIconPrototype}
          className="constrained"
          name="UI icon prototype"
        ></Video>

        <h5>Color system</h5>
        <p>Created an adaptive color system to support a wide range of user needs. The color system was contrast-based in order to anchor our definitions in accesibility. The tool <a href="https://leonardocolor.io" target="_blank">Leonardo</a> was created to help facilitate this work, and was pivotal in accomplishing what I was able to create for Spectrum.</p>
        <p>One unique quality to Spectrum's adaptive theme is that the target contrast ratios passed to Leonardo are generated mathematically. The formula to generate the target contrast ratios is based on a percentage of the available contrast gamut for the current brightness of the theme. In other words, the target ratios adapt in order to retain a perceptually linear distribution of lightnesses, meet or exceed contrast minimums, and to generate colors that do not exceed the available amount of contrast.</p>
        <Video 
          src={VideoAdaptiveTheme}
          className="constrained"
          name="Adaptive theme"
        ></Video>

        <h5>Spacing system</h5>
        <p>Created a basic spacing system to be used when creating layouts. These spacing increments provide flexibility and balance for a variety of design scenarios. The scale was evaluated against existing design system patterns and product designs in order to ensure the system met expectations and needs.</p>
        <Image
          src={SpSpacing}
          className="constrained"
          alt="Spacing units displayed as sized boxes with annotations for name and pixel size."            
        ></Image>

      </Section>

    
      <Section title="Component design">
        <p>Component design included inception of new components, as well as refining contributions from internal teams in order to create a stable component</p>
        <p>Design process included gathering requirements across multiple products, then amalgamating requirements into a feasible middle-ground that could be used to define a core, flexible component that can be used to meet all other requirements.</p>

        <h5>Standardized API for components</h5>
        <p>Formalized options and created new ones evolved into what our team called a "design API". The design API is a set of common properties and options for components. This API predictably defines what options are available and which are mutually exclusive or can be combined with others.</p>
        <p>The design API provided clear guidance for engineering teams implementing components, so that every available variation was presented in each framework as design intended. Options and properties were named in a way that could easily translate to platform terminology. The design API also defined types of properties, such as boolean, enumeration, or custom values. This ensured all implementations were consistent in how variations were implemented.</p>
        <p>A secondary benefit to the design API was that it created a clear framework for our team to define component options in documentation. Sections could be predictably written based on what options we decided a component would support. The sections in Spectrum components' "options" section were also appended with a "table of options", which is a tabular representation of each component's API.</p>
        <p>The design API was also pivotal in standardizing our design tokens, which could be enforced through code and used to generate data for all possible permutations of a component.</p>
        <Image
          src={DesignAPI}
          // className="constrained"
          alt="Xd file with defined API properties and options for components"            
        ></Image>
        <Caption>Xd file with defined API properties and options for components. These options surface in tokens and website documentation for each component.</Caption>

        <h5>Windows high contrast mode</h5>
        <p>Provided redesigned components themed in Windows High Contrast Black theme. The purpose of this work was to provide engineering guidance regarding the Windows color keywords to use in implementation. Worked with the Spectrum-CSS and accessibility teams to ensure the designs met expectations for users who rely on High Contrast Mode, as well as using common conventions for the Windows keywords.</p>
        <p>Ultimately this was a unique translation of Spectrum's design language. Semantics and use of color had to conform to a more simplified semantic set, which needed to rely on OS keywords for end-user customization.</p>
        <p>Output from this effort surfaced as guidance and collaboration with teams, along with custom UI kits available on Spectrum's website.</p>
        <Image
          src={WHCMCheckbox}
          className="constrained"
          // alt="Xd file with defined API properties and options for components"            
        ></Image>
        <Caption>Partial view of Windows high contrast mode UI kit for Checkbox.</Caption>

        <h5>Case study: Tree view</h5>
        <p>Tree view is a core component to many Adobe experiences. In the Experience Cloud, Tree view is a mechanism for file and asset management. In the Creative Cloud, Tree view is customized into the many forms of Layers panels.</p>
        <p>The component needed to be designed to be flexible with clear guardrails. Over 50 requirements were gathered across multiple teams across clouds to identify core functional aspects of Tree view. Many requirements were resolved in the core design of the component, whereas other requirements were resolved with usage guidelines on how to properly extend the component.</p>
        <p>This was an extremely important component to properly design for Spectrum, as Tree views are used for core aspects of user experiences with very different purposes.</p>
        <Image
          src={TreeView}
          className="constrained"
          // alt="Xd file with defined API properties and options for components"            
        ></Image>
        <Caption>Example view of two common use cases for Tree view: asset management and layers panels.</Caption>
      </Section>

      <Section title="Documentation">
        <p>Documentation is a critical aspect of Design systems, and every decision made was documented in some fashion. There were two primary modes of documentation: <b>internal team documentation</b> and <b>external consumer documentation</b>.</p>
        
        <h5>Internal team documentation</h5>
        <p>Core elements of how Spectrum's design language is constructed were documented in the form of annotated Xd files. Our team relied on these "foundational" Xd files for a number of important aspects of how we design. I defined and documented the system we use for creating any list-like component, with specificatoins for possible API properties like size and density. Components that leverage this template include List view, Tree view, Side navigation, Column view, and accordion.</p>
        <Image
          src={SpInternalDocumentation}
          // className="constrained"
          // alt="Xd file with defined API properties and options for components"            
        ></Image>
        <Caption>"Stack items" Xd template file with anatomy, areas, and specifications for size and density options.</Caption>

        <h5>External consumer documentation</h5>
        <p>Every element of Spectrum's design language that was consumer-facing was documented on the Spectrum website. I contributed to documentation for every component or foundational system that I designed, including Tree view, Breadcrumbs, Color, and more.</p>

        <p>This documentation was created for external use, so it needed to be clear, organized, and actionable. This included writing copy, creating visuals, and in some cases refining the information architecture for the site itself.</p>
        <Image
          src={SpDocumentation}
          // className="constrained"
          alt="Browser windows displaying documentation pages for Tree view and Color"            
        ></Image>
        <Caption>Documentation on Spectrum.adobe.com</Caption>

      </Section>

      <Section title="Design prototyping">
        <p>Prototyped tools and examples to assist in complex design problems such as <b>scaling for dynamic type and zoom</b>, <b>responsive opacity checkerboard pattern</b>, <b>responsive iconography styling</b>, <b>color generation and evaluation tools</b>, and <b>personalization features for Adobe products</b>.</p>
        
        <Video 
          src={VideoListItem}
          className="constrained"
          name="List item scaling"
        ></Video>
        <Caption>Prototype for evaluating adaptive design rules for list items, their component parts, and how things scale relative to iOS dynamic type and zoom.</Caption>


      </Section>

      <Section title="Framework partnerships">
        <p>Collaborated with frameworks for CSS, React, Angular, Vue, iOS, macOS, UWP, and 4+ internal Adobe frameworks. Leveraged token system strategy to aid in cross-communication with frameworks teams and evaluating their implementations of Spectrum.</p>
        <p>Set up a recurring cadence of meetings for questions, review, and sharing information once a week. These meetings became pivotal in collaboration across platform teams for designing components and properly supporting product teams.</p>
      </Section>

      <Footer />

    </motion.div>
  )
}
 
export default Spectrum;