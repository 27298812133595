import React from "react"
import { Link } from 'react-router-dom'; 

import Image from "../components/Image";
import Section from "../components/Section";
import {
  pageVariants,
  pageTransition,
  pageStyle
} from '../components/Animation';
import {  motion } from "framer-motion";
import Footer from '../components/Footer';
import Caption from '../components/Caption';


import ProportioHero from '../img/ProportioHero.png';
import ProportioTypeScale from '../img/ProportioTypeScale.png';
import ProportioComponents from '../img/ProportioComponents.png';
import ProportioTokens from '../img/ProportioTokens.png';


function Proportio(props) {
  
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <h1 style={{alignSelf: 'center'}} ><span className="highlight">Proportio</span></h1>
      <a style={{textAlign: 'center'}} href="https://proportio.app/" target="_blank" rel="noreferrer">Visit Proportio.app &#8594;</a>

      <Image
          src={ProportioHero}
          alt="Screenshot of Color and contrast web application"            
        ></Image>
      <Section title="Overview">
        <p>Proportio (<a href="https://proportio.app" target="_blank" rel="noreferrer">https://proportio.app</a>) is an open source tool for creating proportional design systems. It enables the creation of typography, spacing, radius, icon, and elevation systems based on mathematical proportion. This enables a systematic approach to component design, which automatically produces balanced scaling for component sizes and density options.</p>
        
        <h4>My role</h4>
        <p>Vision, creation, and maintaner of the open-source project.</p>

      </Section>

      <Section title="Context and inspiration">
        <p>It started with a design system request. Our design system team was asked to add a larger size option to our existing button component. After looking at the other sizes, we could extrapolate another size, but it got me curious about how all of the component sizes compare across our design system, and if there were universal rules we should follow for sizing?</p>
        <p>Unfortunately, the answer was no. Some values were common, but nothing was consistent. There were no rules in place to help ensure cohesive sizing, spacing, and scale across our system.</p>
        <p>On a <Link to="/spectrum">previous design system team</Link>, I had worked with our designers to create similar rules, however the team I was currently on was not as familiar with the concept of proportional scales.</p>
        <p>I created a prototype to demonstrate how systematic approaches to design could help unify our system and automatically support simple approaches to adding new sizes and densities to our components. Upon realizing the value that this type of tool would provide to the broader industry, I set out to create a more robust application: Proportio.</p>

      </Section>

      <Section title="It all starts with typography">
        <p>Good design begins with typography. From this perspective, Proportio leverages typographic scale to create all the values for text and icon sizes, spacing, radius, and elevations.</p>
        <Image
          src={ProportioTypeScale}
          className="constrained"
          alt="Screenshot of type scale creation with icon and text-icon-pairing sizes"            
        ></Image>
      </Section>

      <Section title="Systematic component sizes and densities">
        <p>With the base proportional scales in place, designers can add larger and smaller component sizes, as well as more or less compact densities. Each additional variation is created by the rules and proportions the designer originally set in place.</p>
        <p>The interface will show "live specifications" as you work in Proportio; demonstrating the values for each attribute of the component in its variations.</p>
        <Image
          src={ProportioComponents}
          className="constrained"
          alt="Component with sizes X-small, small, and medium for density options of comfortable (default) and cozy"            
        ></Image>
        
      </Section>

      <Section title="Token export">
        <p>When a designer is ready to use their system, Proportio exports the values as either W3C Design Tokens or CSS variables. This helps to bridge the gap between design and development, so that Proportio can be used for systematic design with simple hand-off for engineers.</p>
        <Image
          src={ProportioTokens}
          className="constrained"
          alt="Dialog titled 'Export' with W3C tokens displayed in an active tab"            
        ></Image>
      </Section>

      <Section title="Open source">
        <p>The design system community emerged from a very open and collaborative group of people. Over time, our industry has openly shared new tools, ideas, and advancements to help one another. I believe firmly in the spirit of our community, and want Proportio to be a way for designers to learn more about systematic design approaches, as well as elevating their component systems to be more flexible and balanced.</p>
        <p><a href="https://github.com/NateBaldwinDesign/proportio" target="_blank" rel="noreferrer">View Proportio on Github &#8594;</a></p>
      </Section>

      <Footer />

    </motion.div>
  )
}
 
export default Proportio;