import React from "react"
import Image from "./Image";
import { Link } from 'react-router-dom'; 
import './ProjectCard.scss';
import Badge from "./Badge";

const ProjectCard = (props) => {
    const hideDescription = props.hideDescription;
    const description = (hideDescription) ? ' ' : <p>{props.description}</p>;
    const badgeType = props.badgeType;

    return (
      <Link to={props.link} className="projectCard">
        <div className="projectImage">
          <Image src={props.image} alt={props.alt}></Image>
        </div>
        <div className="projectCard_details">
            <h4><span className="highlight">{props.title}</span></h4>
            {description}
            <Badge type={badgeType} />
        </div>
      </Link>
    )

}
 
export default ProjectCard;