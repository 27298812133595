import { Popover as OldPopover } from '@varld/popover';
import Accessibility from '../svg/Accessibility';
import './Popover.scss';

const Popover = (props) => {
  console.log(props.icon)
  return (
    <OldPopover key={props.popoverKey} popover={({ visible, open, close }) => {
      return (
        // <div ref={props.variableRef}>
        <div>
          { props.children }
        </div>
      )
    }}>
      <button className="HeaderButton" tabIndex="0"> 
        {props.icon()}
      </button>
    </OldPopover>
  )
}

export default Popover