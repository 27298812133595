import {
  useState,
  useEffect,
  useRef 
  // setState
} from 'react';
import {useTheme} from 'css-vars-hook';
// import * as Leo from '@adobe/leonardo-contrast-colors';
import React from 'react';
import { AnimatePresence, motion, useViewportScroll } from "framer-motion";

import Logo from './svg/LogoFirstName';

import { 
  BrowserRouter, 
  Route, 
  Switch,
  useParams,
  useLocation,
  // useRouteMatch,
  Link 
} from 'react-router-dom'; 

import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import Accessibility from './svg/Accessibility';
import Popover from './components/Popover';

import Projects from './Projects.js'; 
import Home from './Home.js';
import Articles from './Articles.js'; 
import About from './About.js'; 
import Styleguide from './Styleguide.js'; 

import Leonardo from './projects/Leonardo.js';
import Spectrum from './projects/Spectrum.js';
import Ellucian from './projects/Ellucian.js';
import Exede from './projects/Exede.js';
import FineArt from './projects/FineArt.js';
import ColorAndContrast from './projects/ColorAndContrast';

import "animate.css";
import './Common.scss';
import './App.scss';
import Brightness from './svg/Brightness';
import Contrast from './svg/Contrast';
import Saturation from './svg/Saturation';
import Proportio from './projects/Proportio';
import Resume from './Resume';
import IntuitDesignSystem from './projects/IntuitDesignSystem';


function App(props) {
  const adaptiveTheme = props.adaptiveTheme;
  const colorSwatches = adaptiveTheme.contrastColors;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const location = useLocation();
  const mq = window.matchMedia('(prefers-color-scheme: dark)');
  
  const popoverDisplay = (isPopoverOpen) ? 'block' : 'none';
  const [switchChecked, setSwitchChecked] = useState((mq.matches) ? true : false );
  const switchClassName = (switchChecked) ? 'switch checked' : 'switch';

  const [lightness, setLightness] = useState((mq.matches) ? 8 : 100);
  const [contrast, setContrast] = useState(1);
  const [saturation, setSaturation] = useState(100);
  const [sliderMin, setSliderMin] = useState((mq.matches) ? 0 : 80);
  const [sliderMax, setSliderMax] = useState((mq.matches) ? 30 : 100);

  mq.addEventListener('change', function (evt) {
    props.adaptiveTheme.lightness = ((mq.matches) ? 11 : 100)
    setLightness((mq.matches) ? 11 : 100)
    setSliderMin((mq.matches) ? 0 : 80);
    setSliderMax((mq.matches) ? 30 : 100);
  });

  const _createThemeObject = () => {
    let themeObj = {}
    props.adaptiveTheme.contrastColors.forEach(color => {      
      if(color.name) {
        let values = color.values;
        values.forEach(instance => {
          let name = instance.name;
          let val = instance.value;
          themeObj[name] = val;
        });
      } else {
        // must be the background
        let name = 'background'
        let val = color.background;
        themeObj[name] = val;
      }
    })
    return themeObj;
  };

  const theme = useState( _createThemeObject() );

  const {setRef, setVariable} = useTheme(theme);

  function _updateColorVariables() {
    // console.log("Updating color variables");

    let themeInstance = _createThemeObject();    
    for (const [key, value] of Object.entries( themeInstance )) {
      setVariable(key, value);
    }
  };
  _updateColorVariables();

  const AppClassName = (switchChecked) ? "App dark" : "App light";

  return (
      <div 
        className={AppClassName} 
        ref={setRef}
        >
        <div className="App-header">
          <AnimatePresence initial={false}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <div className="App-header-content">
                {/* <img src={logo} className="logo" alt="Logo Image" /> */}
                <Link to="/" className="logo"  >
                  <Logo />
                </Link>

                <div className="App-header-content--right">
                  <Switch>
                    <Route exact path='/' component={Header} />
                    <Route path='/:page' component={Header} />

                    </Switch>
                    <div className="Popover-wrapper">
                      <Popover 
                        key={props.adaptiveTheme.contrastColors} 
                        popoverKey={props.adaptiveTheme.contrastColors} 
                        variableRef={setRef}
                        icon={Brightness}
                      >
                          <fieldset>
                            <div>
                              <label htmlFor="darkMode">
                                Dark mode
                              </label>
                              <label className={switchClassName}>
                                <input 
                                  type="checkbox"
                                  className="switch-input"
                                  id="darkMode"
                                  defaultChecked={switchChecked}
                                  onChange={(e) => {
                                    const isDarkMode = e.target.checked;
                                    props.adaptiveTheme.lightness = ((isDarkMode) ? 11 : 100)
                                    setLightness((isDarkMode) ? 11 : 100)
                                    setSliderMin((isDarkMode) ? 0 : 80);
                                    setSliderMax((isDarkMode) ? 30 : 100);
                                    setSwitchChecked(isDarkMode)
                                  }}    
                                />
                                <span className="switch-label"
                                  data-on="On"
                                  data-off="Off">
                                </span>
                                <span className="switch-handle"></span>
                              </label>
                            </div>
                            <div>
                              <label htmlFor="brightness">
                                Lightness
                              </label>
                              <input
                                value={lightness}
                                id="brightness"
                                type="range"
                                min={ sliderMin }
                                max={ sliderMax }
                                step="1"
                                onChange={e => {
                                  setLightness(e.target.value)
                                  props.adaptiveTheme.lightness = e.target.value
                                  _updateColorVariables()
                                }}
                              />
                            </div>
                          </fieldset>
                      </Popover>
                    </div>

                    <div className="Popover-wrapper">
                      <Popover 
                        key={props.adaptiveTheme.contrastColors} 
                        popoverKey={props.adaptiveTheme.contrastColors} 
                        variableRef={setRef}
                        icon={Contrast}
                      >
                          <fieldset>
                            <div>
                              <label htmlFor="contrast">
                                Contrast
                              </label>
                              <input
                                value={contrast}
                                id="contrast"
                                type="range"
                                min="0.25"
                                max="3"
                                step="0.025"
                                onChange={e => {
                                  setContrast(e.target.value)
                                  props.adaptiveTheme.contrast = e.target.value
                                  _updateColorVariables()
                                }}
                              />
                            </div>
                          </fieldset>
                      </Popover>
                    </div>
                    
                    <div className="Popover-wrapper">
                      <Popover 
                        key={props.adaptiveTheme.contrastColors} 
                        popoverKey={props.adaptiveTheme.contrastColors} 
                        variableRef={setRef}
                        icon={Saturation}
                      >
                          <fieldset>
                            <div>
                              <label htmlFor="saturation">
                                Saturation
                              </label>
                              <input
                                value={saturation}
                                id="saturation"
                                type="range"
                                min="0"
                                max="300"
                                step="1"
                                onChange={e => {
                                  setSaturation(e.target.value)
                                  props.adaptiveTheme.saturation = e.target.value
                                  _updateColorVariables()
                                }}
                              />
                            </div>
                          </fieldset>
                      </Popover>
                    </div>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
        
        <div className="App-content">
          <AnimatePresence exitBeforeEnter initial={false}> 
          <ScrollToTop />

            <Switch location={location} key={location.pathname}> 
            {/* <Switch> */}
              <Route exact path="/">
                <Home colors={props.adaptiveTheme.colors} colorSwatches={colorSwatches} />
              </Route>
              <Route path="/projects" component={Projects} />
              <Route path="/articles" component={Articles} />
              {/* <Route path="/about" component={About} /> */}
              <Route path="/styleguide">
                {/* <Styleguide></Styleguide> */}
                <Styleguide colors={props.adaptiveTheme.colors} colorSwatches={colorSwatches}/>
              </Route>

              <Route path="/ColorAndContrast" >
                <ColorAndContrast />
              </Route>
              <Route path="/Leonardo" >
                <Leonardo />
              </Route>
              <Route path="/Spectrum" >
                <Spectrum />
              </Route>
              <Route path="/IntuitDesignSystem" >
                <IntuitDesignSystem />
              </Route>
              <Route path="/Ellucian" >
                <Ellucian />
              </Route>
              <Route path="/Exede" >
                <Exede />
              </Route>
              <Route path="/Proportio">
                <Proportio />
              </Route>
              {/* <Route path="/FineArt" >
                <FineArt />
              </Route> */}
              <Route path="/Resume">
                <Resume />
              </Route>
            </Switch>
          </AnimatePresence>

        </div>
      </div>
  );
}

export default App;
