import * as React from "react"

function IntuitLogoSquare(props) {
  return (
<svg width="196" height="196" viewBox="0 0 196 196" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="196" height="196" fill="#236CFF"/>
<path d="M100.08 100.552C100.08 108.472 106.422 114.261 114.474 114.261C122.527 114.261 128.873 108.481 128.873 100.552V82.3714H121.071V99.6187C121.071 103.891 118.216 106.756 114.453 106.756C110.689 106.756 107.834 103.891 107.834 99.6187V82.3714H100.04L100.08 100.552Z" fill="white"/>
<path d="M149.719 89.6684H159.025V113.629H166.822V89.6684H176.124V82.3714H149.719V89.6684Z" fill="white"/>
<path d="M144.679 82.3714H136.882V113.629H144.679V82.3714Z" fill="white"/>
<path d="M68.5789 89.6684H77.8848V113.629H85.6823V89.6684H94.9842V82.3714H68.5789V89.6684Z" fill="white"/>
<path d="M27.6736 82.3714H19.8764V113.629H27.6736V98.0001V82.3714Z" fill="white"/>
<path d="M64.4749 95.4483C64.4749 87.5279 58.1296 81.739 50.0765 81.739C42.0235 81.739 35.6781 87.519 35.6781 95.4483V113.629H43.4753V96.3814C43.4753 92.1095 46.3347 89.2437 50.0942 89.2437C53.8538 89.2437 56.7132 92.1095 56.7132 96.3814V113.629H64.5105L64.4749 95.4483Z" fill="white"/>
</svg>

  )
}

export default IntuitLogoSquare