import React from "react"
import ProjectCard from "./components/ProjectCard";
import LeonardoScreenshot from './img/Leonardo_Screenshot.png';
import StyleGuideMacbook from './img/EllucianStyleGuide_MacBook.png';
import PatternLibrary from './img/ExedePatternLibrary.png';
// import SpectrumScreenshot from './img/Spectrum_Screenshot.png';
import SpectrumScreenshot from './img/Spectrum_Hero.png';

import { BrowserRouter, Route, Link, useParams, useRouteMatch, Switch } from 'react-router-dom'; 
import { AnimatePresence, motion } from "framer-motion";
import Footer from './components/Footer';
import SelfPortrait1 from './img/Self-Portrait-Nate-Baldwin.jpg';
import SelfPortrait2 from './img/Self-Portrait-2-Nate-Baldwin.jpg';
import ColorAndConrast from './img/ColorAndContrastHome.png';
import ProportioScreenshot from './img/ProportioScreenshot.png';
import IntuitDesignSystemHero from './img/IDS_Hero.png';

import {
  pageVariants,
  pageTransition,
  pageStyle
} from './components/Animation';
import IntuitLogo from "./svg/IntuitLogo";
import AdobeLogo from "./svg/AdobeLogo";
import EllucianLogo from "./svg/EllucianLogo";
import ViasatLogo from "./svg/ViasatLogo";
import Section from "./components/Section";
import Swatch from "./components/Swatch";
import Ramp from "./components/Ramp";
import ArticleCard from "./components/ArticleCard";

function Home(props) {
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="intro">
        <section className="intro_Section">
          <h1 className="intro_Heading">Nate Baldwin</h1>
          <h4 className="intro_SubHeading">I help teams build beautiful, scalable design systems.</h4>
        </section>
        <div className="intro_SpecializationList-Wrapper">
            <h5 className="h7">Specializations</h5>
            <ul className="intro_SpecializationList">
              <li>Color</li>
              <li>Design foundations</li>
              <li>Design tokens</li>
              <li>Component systems</li>
              {/* <li>Documentation</li> */}
            </ul>
          </div>
      </div>

      <section className="HomeSection">
        <div className="HomeSection_Item">
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <p className="large">
              I'm a <strong className="highlight">Principal Product Designer</strong> specializing in design systems. I focus on scaling design for large companies and creating tools and resources for the next generation of designers.
            </p>
            {/* <p className="large">I've worked with companies large and small, building <strong>multi-platform design systems</strong> for consumer-facing and B2B products.</p> */}
            {/* <Link className="projectHeader_Link button" to="/projects">View design systems</Link> */}
          </div>
          {/* <div className="LogoWrapper">
              <ul className="LogoList">
                  <li><Link  to="/intuitdesignsystem"><IntuitLogo/></Link></li>
                  <li><Link to="/spectrum"><AdobeLogo/></Link></li>
              </ul>
              <ul className="LogoList">
                  <li><Link to="/ellucian"><EllucianLogo/></Link></li>
                  <li><Link to="/exede"><ViasatLogo/></Link></li>
              </ul>
          </div> */}
        </div>
      </section>

      <span className="projectHeader">
        <h2><span className="highlight">Select work</span></h2><Link className="projectHeader_Link button" to="/projects">View all projects</Link>
      </span>

      <div className="projectCardWrapper" style={{marginTop: '0'}}>
      <ProjectCard 
          title="Intuit Design System"
          image={IntuitDesignSystemHero}
          alt="Intuit design system assets"
          description="Multi-brand, themeable design system for Intuit products and product design systems. Supporting Quickbooks, TurboTax, Mailchimp, Mint, and Credit Karma."
          link="/intuitdesignsystem"
          badgeType="designSystem"
        ></ProjectCard>

        <ProjectCard 
          title="Spectrum Design System"
          image={SpectrumScreenshot}
          alt="Spectrum design system documentation website"
          description="Multi-platform enterprise design system for Adobe products. Supported resources for design, web, iOS, Android, UWP, and custom frameworks for 100+ products worldwide."
          link="/spectrum"
          badgeType="designSystem"
        ></ProjectCard>

      <ProjectCard 
          title="Proportio"
          image={ProportioScreenshot}
          alt="Screenshot of Leonardo web application"
          description="Web app for creating proportional design systems. Sizing values are based on typographic scales to ensure balanced sizes and densities for components."
          link="/proportio"
          badgeType="designTool"
        ></ProjectCard>

        <ProjectCard 
          title="Leonardo"
          image={LeonardoScreenshot}
          alt="Screenshot of Leonardo web application"
          description="Web tool for creating accessible color palettes for design systems. Built with a sibling javascript module for generating colors in web applications."
          link="/leonardo"
          badgeType="designTool"
          // hideDescription
        ></ProjectCard>

      <ProjectCard 
          title="Color & Contrast.com"
          image={ColorAndConrast}
          alt="Screenshot of Color and Contrast web site"
          description="An interactive online library for color and contrast perception. Subjects are organized, cited, and have context regarding how they affect user interface design."
          link="/colorandcontrast"
          badgeType="website"
          // hideDescription
        ></ProjectCard>

        <ProjectCard 
          title="Ellucian Design System"
          image={StyleGuideMacbook}
          alt="Screenshot of Ellucian style guide"
          description="Multi-platform design system for accessible and themable higher education products"
          link="/ellucian"
          badgeType="designSystem"
        ></ProjectCard>
      </div>

      <section className="HomeSection">
        <span className="HomeSectionHeader">
            <h2><span className="highlight">My story</span></h2>
        </span>
        <div className="HomeSection--longForm">
          <p>My career began as an aspiring <a href="https://natebaldwinfineart.com/" target="_blank" rel="noreferrer"><strong>hyperrealist painter</strong></a> and illustrator.</p>
          <p>Out of curiosity, I began to <strong>teach myself web development</strong>, and I fell in love with web and digital product design.</p>
          <p>I took my first <strong>graphic design</strong> position at ViaSat Inc, where I grew my skills in visual communication, data-driven design, and art direction. As a <i>"design team of one"</i>, I was driven to find ways of scaling my work to meet the demands of multi-channel marketing campaigns and vendor collaboration. Here, I collaborated with developers on my first <Link to="/exede">design system</Link> (called a "pattern library" at the time).</p>
          <p>After some time, I shifted my direction towards <strong>user experience design</strong> at Ellucian, where I created their first <Link to="ellucian">design system</Link> from the ground-up.</p>
          <p>Since then, I spent 6 years working on <Link to="/spectrum">Adobe's design system, Spectrum</Link>. I pushed the envelope of what design systems can do for products, including the creation of a complex system for <strong>color and contrast personalization</strong>.</p>
          <p>I've found a passion for sharing my work, ideas, and tools with others in the industry. My hope is to grow our collective understanding and improve design quality across the board. I built <Link to="/leonardo">LeonardoColor.io</Link> and <Link to="/proportio">Proportio.app</Link> as free tools for designers, as well as <Link to="/colorandcontrast">ColorAndContrast.com</Link> to help educate designers on color.</p>
          <p>Currently I work on the <Link to="intuitdesignsystem">Intuit Design System</Link> team, where my focus area is on creating a Design Token system that supports <strong>multi-brand theming</strong>.</p>
          <p>My work has been shared several times, and I've been featured as a <a href="https://www.smashingmagazine.com/person-of-the-week/nate-baldwin/" target="_blank" rel="noreferrer">person of the week</a> by Smashing Magazine. I was also a finalist on ZeroHeight's <a href="https://zeroheight.com/events/design-system-awards/finalists/nate-baldwin/" target="_blank" rel="noreferrer">2023 Design Systems Awards</a>.</p>
        </div>
      </section>

      <section className="HomeSection">
        <span className="HomeSectionHeader">
            <h2><span className="highlight">Recent articles</span></h2>
        </span>
        <div className="ArticleCardWrapper ArticleCardWrapper--column">
          <ArticleCard
            title="Creating a flexible design token taxonomy for Intuit’s Design System"
            publisher="Medium.com"
            image="https://miro.medium.com/v2/resize:fit:4800/format:webp/1*YKuyPYiEJC7KHxnIuJYeTQ.png"
            alt="TurboTax interface with annotations of tokens used to style the UI"
            description="This article covers how we evolved our tokens at Intuit to create a flexible taxonomy for a small but powerful set of semantic tokens."
            link="https://medium.com/@NateBaldwin/creating-a-flexible-design-token-taxonomy-for-intuits-design-system-81c8ff55c59b"
          ></ArticleCard>
          
          <ArticleCard 
            title="Proportional design systems with Proportio.app"
            publisher="Medium.com"
            image="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*CYNPzGZP7Ln79sNvAW1Vlw.png"
            alt="Proportio logo"
            description="Introducing Proportio: a web app for designers to create a proportional design system. Explore how typographic scales can be applied to icons, spacing, and components."
            link="https://medium.com/design-bootcamp/proportional-design-systems-with-proportio-app-2dd1d27f7206"
          ></ArticleCard>

        <ArticleCard 
            title="Reinventing Adobe Spectrum’s colors"
            publisher="Adobe.design"
            image="https://adobe.design/stories/design-for-scale/media_1ecc3af733fdeab94b89e9ad0f9afd309ffea0896.jpeg"
            alt="Orderly grid of colored tiles arranged on a surface but scattered to be reorganized"
            description="A detailed overview of re-inventing Adobe Spectrum's colors with a dynamic, adaptive, and scientifically based system."
            link="https://adobe.design/stories/design-for-scale/reinventing-adobe-spectrum-s-colors"
        ></ArticleCard>
        </div>
        {/* <div className="HomeSection_Item HomeSection_Item--fullWidth">
            <div className="YouTube_Embed">
              <iframe className="YouTubeVideo" src="https://www.youtube.com/embed/Y9-aXiMSKa4?rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
        </div> */}
      </section>
      
      <section className="HomeSection">
        <span className="HomeSectionHeader">
            <h2><span className="highlight">Speaking</span></h2>
        </span>
        <ul className="HomeSectionList">
          <li><a href="https://youtu.be/Yli6_t3zgYM?si=WdLUFK8XNkyq3VwJ" target="_blank" rel="noreferrer">Color and contrast for accessible interfaces</a> <span className="HomeSectionList_Details">FRONT, 2023</span></li>
          <li><a href="https://youtu.be/Yli6_t3zgYM?si=WdLUFK8XNkyq3VwJ" target="_blank" rel="noreferrer">Systematizing Design</a> <span className="HomeSectionList_Details">Product Hive, 2017</span></li>
        </ul>
      </section>


      <Footer />
    </motion.div>
  )
}

export default Home;