import React from "react"
import Ramp from './components/Ramp'
import Swatch from './components/Swatch'
import Section from './components/Section'
import Footer from './components/Footer';
import { AnimatePresence, motion } from "framer-motion";


import {
  pageVariants,
  pageTransition,
  pageStyle
} from './components/Animation';


function Styleguide(props) {
  const n = window.innerWidth;
  const colors = props.colors;
  const colorSwatches = props.colorSwatches;
  const graySwatches = colorSwatches[1].values;
  const oceanBlueSwatches = colorSwatches[2].values;
  const orangeSwatches = colorSwatches[3].values;

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <h1><span className="highlight">Style guide</span></h1>
      <p className="body5">
        The design system for this portfolio has been built with an <b>adaptive theme</b>, which enables users to modify the colors to enhance their specific and unique needs.
      </p>
      <p className="body5">
        Use the lightness and contrast sliders in the header menu to see the color palette change to reflect your adjustments.
      </p>
      <p className="body5">
        Dark mode support is automatic based on device preferences.
      </p>
      <Section title="Adaptive color theme">
        <p>Color theme defined and implemented using <a href="https://leonardocolor.io">Leonardo</a>.</p>
        <div className="colorGroup">
          <Ramp colors={colors[0].backgroundColorScale} />
          {graySwatches.map((color) => <Swatch key={`${color.contrast + color.value}`} color={color.value} name={color.name} contrast={color.contrast} />)}
        </div>
        <div className="colorGroup">
          <Ramp colors={colors[1].backgroundColorScale} />
          {oceanBlueSwatches.map((color) => <Swatch key={`${color.contrast + color.value}`} color={color.value} name={color.name} contrast={color.contrast} />)}          
        </div>
        <div className="colorGroup">
          <Ramp colors={colors[2].backgroundColorScale} />
          {orangeSwatches.map((color) => <Swatch key={`${color.contrast + color.value}`} color={color.value} name={color.name} contrast={color.contrast} />)}          
        </div>
      </Section>

      <Section title="Typography">
        <p>Fonts in use:</p>
        <ul>
          <li><a href="https://github.com/noirblancrouge/YoungSerif" target="blank">Young Serif</a></li>
          <li><a href="https://github.com/noirblancrouge/YoungSerif" target="blank">Lato Sans</a></li>
        </ul>
        <h1><span className="highlight">Heading 1</span></h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <p className="body1">Body 1</p>
        <p className="body2">Body 2</p>
        <p className="body3">Body 3</p>
        <p className="body4">Body 4</p>
        <p className="body5">Body 5</p>
      </Section>

      <Footer />
    </motion.div>
  )
}
 
export default Styleguide;