const pageVariants = {
  initial: {
    opacity: 0,
    y: '18px',
    // scale: 0.8
  },
  in: {
    opacity: 1,
    y: 0,
    // scale: 1
  },
  out: {
    opacity: 0,
    y: '18px',
    // scale: 1.2
  }
};

const pageTransition = {
  // type: "tween",
  // ease: "anticipate",
  duration: 0.3,
  delay: 0
};

const pageStyle = {
  position: "relative"
};

export {
  pageVariants,
  pageTransition,
  pageStyle
}