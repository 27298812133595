import React from "react"
import './Section.scss';

const Section = (props) => {
    const className = props.className

    return (
      <section className={className}>
        <h3>
          <span className="highlight">
            {props.title}
          </span>
        </h3>

        {props.children}
      </section>
    )
  
}
 
export default Section;